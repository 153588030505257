import { Avatar, Box, Button, Chip, Tab, Tabs } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PageHeader from '../../global/components/PageHeader'
import { wrapperSx } from '../../global/helpers/globalstyles'
import PageInsideHeader from '../../global/components/PageInsideHeader'
import useLoadingHook from '../../global/hooks/UseLoadingHook'
import { Link, useParams } from 'react-router-dom'
import { useUserStore } from '../../global/store/UserStore'
import { useSnackbarStore } from '../../global/store/SnackbarStore'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { formatDate, getName } from '../../global/helpers/text'
import NoRows from '../../global/components/NoRows'
import { getCorrectionsBySchoolId, getCorrectionsInvoicesBySchoolId } from '../actions/api'

const SchoolCorrectionsInvoices = () => {

    const {id} = useParams();
    const currentUser = useUserStore((state) => state);
    const snackbar = useSnackbarStore((state) => state);
    const [invoicesList, invoicesLoading, , fetchInvoices] = useLoadingHook(getCorrectionsInvoicesBySchoolId);

    useEffect(() => {
        fetchInvoices(Number(id));
    }, []);

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 75,
            sortable: true,
        },
        {
            field: 'title', headerName: 'Наименование счета', width: 500, sortable: false,
            renderCell: ({row}) => {
                return <React.Fragment>
                    <Avatar
                        sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                    />
                    Счет на оплату по услугам корректировки за {row.month} {row.year} г.
                </React.Fragment>;
            }
        },
        {
          field: 'sum', headerName: 'Сумма счета', width: 300, sortable: false,
          renderCell: ({row}) => {
              return <React.Fragment>
                  {row.sum} тг.
              </React.Fragment>;
          }
        },
        {
            field: 'created_at',
            headerName: 'Дата',
            width: 150,
            sortable: true,
            renderCell: ({row}) => {
                return formatDate(row.created_at)
            }
        },
        {
            field: 'download', headerName: 'Действия', width: 300, sortable: false,
            renderCell: ({row}) => {
                return (
                    <Button 
                        href={`/schools/${id}/corrections-invoices/${row.id}`} 
                        target={"_blank"} rel="noreferrer"
                        sx={{height: 'auto'}}
                    >
                        Перейти к счету
                    </Button>
                )
            }
        },
        {
            field: 'paid', headerName: 'Статус оплаты', width: 300, sortable: false,
            renderCell: ({row}) => {
                return (
                    <Chip label={`${row.paid ? 'Оплачен' : 'Не оплачен'}`} color={`${row.paid ? 'primary' : 'error'}`}/>
                )
            }
        },
    ];

    return (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <PageHeader />
            <Box sx={{...wrapperSx}}>
              <PageInsideHeader
                  text={'Счета на оплату (корректировки)'}
                  subtext={'На данной странице вы можете просмотреть историю счетов на оплату вашей учебной организации по услугам корректировки курсантов'}
              />
              <DataGrid
                  columns={columns}
                  rows={invoicesList ? invoicesList : []}
                  loading={invoicesLoading}
                  initialState={{
                      pagination: {
                          paginationModel: {
                              pageSize: 30,
                          },
                      },
                  }}
                  pageSizeOptions={[30]}
                  disableRowSelectionOnClick={true}
                  autoHeight={true}
                  slots={{
                      noRowsOverlay: NoRows
                  }}
              />
            </Box>
        </Box>
    )
}

export default SchoolCorrectionsInvoices