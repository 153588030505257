import React, {useEffect, useState} from 'react';
import {DataGrid, GridColDef, GridRenderCellParams, GridRowModel} from "@mui/x-data-grid";
import {formatDateTime, getName} from "../../global/helpers/text";
import {Avatar, Button, CircularProgress, Stack, Tab, Tabs} from "@mui/material";
import NoRows from "../../global/components/NoRows";
import useLoadingHook from "../../global/hooks/UseLoadingHook";
import {approveCorrection, getAllCorrections} from "../actions/api";
import {Link} from "react-router-dom";
import {DirectionsCar} from "@mui/icons-material";
import LoadingButton from "../../global/components/LoadingButton";

const AdminCorrectionButton = (row: GridRowModel<any>, fetchCorrections: (tab: string) => void, selectedTab: string) => {
    const [executeInfo, executeLoading, , executeCorrection] = useLoadingHook(approveCorrection);

    useEffect(() => {
        if (executeInfo) {
            fetchCorrections(selectedTab);
        }
    }, [executeInfo])

    return (
        <Stack>
            <LoadingButton
                disabled={row.done}
                color={'primary'}
                variant={'contained'}
                onClick={() => executeCorrection(Number(row.id))}
                sx={{height: 'auto'}}
                loading={executeLoading}
            >
                Принять исправление
            </LoadingButton>
        </Stack>
    );
}

const AdminCorrectionsGrid = () => {

    const [correctionsList, correctionsLoading, , fetchCorrections] = useLoadingHook(getAllCorrections);
    const [selectedTab, setSelectedTab] = useState<string>('false');

    useEffect(() => {
        fetchCorrections(selectedTab)
    }, []);

    useEffect(() => {
        fetchCorrections(selectedTab)
    }, [selectedTab])

    const tabs = {
        created: {id: 'false', label: `Новые`},
        approved: {id: 'true', label: `Подтвержденные`},
    };

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 75,
            sortable: true,
        },
        {
            field: 'previous_fio', headerName: 'Предыдущее ФИО', width: 300, sortable: false,
            renderCell: ({row}) => {
                const name = getName(row);
                return <React.Fragment>
                    <Avatar
                        sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                    />
                    {row.previous_last_name} {row.previous_first_name} {row.previous_patronymic}
                </React.Fragment>;
            }
        },
        {
            field: 'new_fio', headerName: 'Новое ФИО', minWidth: 300, sortable: false,
            renderCell: ({row}) => {
                const name = getName(row);
                return <React.Fragment>
                    <Avatar
                        sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                    />
                    {row.new_last_name} {row.new_first_name} {row.new_patronymic}
                </React.Fragment>;
            }
        },
        {
            field: 'previous_iin',
            headerName: 'Предыдущий ИИН',
            width: 175,
            sortable: false,
        },
        {
            field: 'new_iin',
            headerName: 'Новый ИИН',
            width: 175,
            sortable: false,
        },
        {
            field: 'school.title',
            headerName: 'Название учебной организации',
            headerClassName: 'datagrid--header',
            minWidth: 500,
            sortable: false,
            flex: 1,
            renderCell: ({row}) => {
                return <React.Fragment>
                    <Link
                        to={`/schools/${row.school.id}`}
                        style={{display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black'}}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Avatar
                            sx={{color: '#99999F', bgcolor: '#D9E8FF', mr: 2}}
                        >
                            <DirectionsCar fontSize="small"/>
                        </Avatar>
                        {row.school.title}
                    </Link>
                </React.Fragment>;
            }
        },
        {
            field: 'group.title',
            headerName: 'Группа',
            minWidth: 100,
            sortable: false,
            renderCell: ({row}) => {
                return <React.Fragment>
                    <Link
                        to={`/groups/${row.group.id}`}
                        style={{display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black'}}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {row.group.title}
                    </Link>
                </React.Fragment>;
            }
        },
        {
            field: 'actions',
            headerName: 'Действия',
            width: 300,
            sortable: false,
            renderCell:({row}: GridRowModel<any>) => AdminCorrectionButton(row, fetchCorrections, selectedTab)
        },
        {
            field: 'resolver', headerName: 'Исполнитель', width: 300, sortable: false,
            renderCell: ({row}) => {

                if (!row.resolver) {
                    return 'Отсутствует'
                }

                const name = getName(row.resolver);
                return <React.Fragment>
                    <Avatar
                        sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                    />
                    {name.fullName}
                </React.Fragment>;
            }
        },
    ];

    return (
        <Stack>
            <Tabs
                value={selectedTab}
                onChange={(e, newValue) => setSelectedTab(newValue)}
                variant="scrollable"
                scrollButtons={false}
                sx={{mt: 2, ml: 2}}
            >
                {Object.values(tabs).map(tab => <Tab label={tab.label} key={tab.id} value={tab.id}/>)}
            </Tabs>
            <DataGrid
                columns={columns}
                rows={correctionsList ? correctionsList : []}
                loading={correctionsLoading}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 30,
                        },
                    },
                }}
                pageSizeOptions={[30]}
                disableRowSelectionOnClick={true}
                autoHeight={true}
                slots={{
                    noRowsOverlay: NoRows
                }}
            />
        </Stack>
    );
};

export default AdminCorrectionsGrid;