import React, {useState} from 'react';
import PageHeader from "../../global/components/PageHeader";
import {wrapperSx} from "../../global/helpers/globalstyles";
import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Container,
    Paper,
    Stack,
    Tab,
    Tabs,
    Typography,
    useTheme
} from "@mui/material";
import StorageIcon from "@mui/icons-material/Storage";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import {
    AccessTime,
    Assignment,
    Copyright,
    DirectionsCar, House,
    LocalOffer,
    LocalPhone,
    Login, MoreHoriz, Quiz,
    WorkspacePremium
} from '@mui/icons-material';
import SchoolIcon from "@mui/icons-material/School";
import {testButtonSx} from "../../Test/components/TestsList";
import useMediaQuery from "@mui/material/useMediaQuery";
import ActionButton from "../components/ActionButton";
import {useNavigate} from "react-router-dom";
import KursantIcon from "../../global/assets/images/KursantIcon";
import InfoIcon from "@mui/icons-material/Info";

const SchoolLandingPage = () => {

    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [selectedTab, setSelectedTab] = useState<string>('school');

    return selectedTab === 'school' ? (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <PageHeader/>
            <Box sx={{...wrapperSx, py: 3, background: '#F8F9FB'}} display={'flex'} flexDirection={'column'} gap={2}>
                <Stack flexDirection={'row'} justifyContent={'center'}>
                    <Tabs
                        value={selectedTab}
                        onChange={(e, newValue) => setSelectedTab(newValue)}
                    >
                        <Tab label={'Учебные организации'} value={'school'}/>
                        <Tab label={'Курсанты'} value={'kursant'}/>
                    </Tabs>
                </Stack>

                <Stack
                    flexDirection={'column'}
                    sx={{
                        background: '#EFF',
                        borderRadius: '25px',
                        p: 5,
                        width: 'fit-content',
                    }}
                    alignItems={'center'}
                    alignSelf={'center'}
                >
                    <Typography
                        sx={{
                            fontWeight: 'bold'
                        }}
                    >
                        Уважаемые пользователи, уведомляем о том, что произошел перенос интегрированной версии сайта с AIS.Kursant.KZ на основной домен Kursant.KZ.
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                            mt: 1
                        }}
                    >
                        Для просмотра данных старой версии сайта, неинтегрированной с АИС Автошкола, просим перейти по ссылке:
                    </Typography>
                    <Typography>
                        <a
                            href={'https://old.kursant.kz/'}
                            target={'_blank'}
                        >
                            https://old.kursant.kz/
                        </a>
                    </Typography>
                </Stack>

                <Container component="main" maxWidth="xl">

                    <Box id="actions" sx={{ my: 4 }} display={'flex'} flexDirection={'row'} justifyContent={'center'} gap={isMobile ? 1 : 3}>
                        <ActionButton
                            title={'Войти'}
                            subtitle={'В качестве специалиста учебной организации'}
                            backgroundImage={'url(/images/login.png)'}
                            onClick={() => {navigate('/auth')}}
                        />

                        <ActionButton
                            title={'Регистрация'}
                            subtitle={'В качестве специалиста учебной организации'}
                            backgroundImage={'url(/images/register.png)'}
                            onClick={() => {navigate('/register')}}
                        />
                    </Box>


                    <Box id="about" sx={{ my: 4 }}>
                        <Typography variant="h4" component="h1" gutterBottom>
                            О системе
                        </Typography>
                        <Typography variant="body1">
                            {/* Содержимое раздела "О нас" */}
                            Информационная система "Kursant KZ" является инновационным программным обеспечением, позволяющим учебным организациям по подготовке водителей транспортных средств цифровизировать свою учебную деятельность.
                        </Typography>
                    </Box>

                    <Box id="information" sx={{ my: 4 }}>

                        <Typography variant="body1">
                            В целях дальнейшей автоматизации своей деятельности автошколы могут вносить информацию об:
                        </Typography>
                        <Stack flexDirection={'row'} alignItems={'center'} gap={2} sx={{py: 1}}>
                            <Avatar
                                sx={{color: '#163355', bgcolor: '#ec9c44'}}
                            >
                                <StorageIcon />
                            </Avatar>
                            <Typography>
                                Учебно-материальной базе (оснащенность кабинетами/тренажерами)
                            </Typography>
                        </Stack>
                        <Stack flexDirection={'row'} alignItems={'center'} gap={2} sx={{py: 1}}>
                            <Avatar
                                sx={{color: '#163355', bgcolor: '#ec9c44'}}
                            >
                                <PersonAddIcon />
                            </Avatar>
                            <Typography>
                                Сведения о сотрудниках учебной организации
                            </Typography>
                        </Stack>
                        <Stack flexDirection={'row'} alignItems={'center'} gap={2} sx={{py: 1}}>
                            <Avatar
                                sx={{color: '#163355', bgcolor: '#ec9c44'}}
                            >
                                <DirectionsCar />
                            </Avatar>
                            <Typography>
                                Сведения об учебных транспортных средствах
                            </Typography>
                        </Stack>
                        <Stack flexDirection={'row'} alignItems={'center'} gap={2} sx={{py: 1}}>
                            <Avatar
                                sx={{color: '#163355', bgcolor: '#ec9c44'}}
                            >
                                <SchoolIcon />
                            </Avatar>
                            <Typography>
                                Сведения о группах
                            </Typography>
                        </Stack>
                    </Box>

                    <Box id="security" sx={{ my: 4 }}>
                        <Typography variant="h4" component="h1" gutterBottom sx={{textAlign: 'right'}}>
                            Автоматизация учебной деятельности
                        </Typography>
                        <Typography variant="body1" sx={{textAlign: 'right'}}>
                            После внесения вышеупомянутой информации, учебная организация может пользоваться модулем документации.
                        </Typography>
                        <Typography variant="body1" sx={{textAlign: 'right'}}>
                            Модуль документации представляет автоматическую генерация документов, затрагивающих учебную деятельность:
                        </Typography>
                        <Stack flexDirection={'row-reverse'} alignItems={'center'} gap={2} sx={{py: 1}}>
                            <Avatar
                                sx={{color: '#163355', bgcolor: '#ec9c44'}}
                            >
                                <Assignment />
                            </Avatar>
                            <Typography>
                                Приказ о начале обучения учебной группы
                            </Typography>
                        </Stack>
                        <Stack flexDirection={'row-reverse'} alignItems={'center'} gap={2} sx={{py: 1}}>
                            <Avatar
                                sx={{color: '#163355', bgcolor: '#ec9c44'}}
                            >
                                <Assignment />
                            </Avatar>
                            <Typography>
                                Протокол экзаменов
                            </Typography>
                        </Stack>
                        <Stack flexDirection={'row-reverse'} alignItems={'center'} gap={2} sx={{py: 1}}>
                            <Avatar
                                sx={{color: '#163355', bgcolor: '#ec9c44'}}
                            >
                                <Assignment />
                            </Avatar>
                            <Typography>
                                Приказ о выпуске учебной группы
                            </Typography>
                        </Stack>
                        <Stack flexDirection={'row-reverse'} alignItems={'center'} gap={2} sx={{py: 1}}>
                            <Avatar
                                sx={{color: '#163355', bgcolor: '#ec9c44'}}
                            >
                                <Assignment />
                            </Avatar>
                            <Typography>
                                Свидетельство об окончании обучения
                            </Typography>
                        </Stack>
                    </Box>

                    <Box id="about" sx={{ my: 4 }}>
                        <Typography variant="h4" component="h1" gutterBottom>
                            Конфиденциальность и безопасность данных
                        </Typography>
                        <Typography variant="body1">
                            Все чувствительные (конфиденциальные и персональные данные учебной организации/курсантов) подвергаются шифрованию перед внесением в базу данных системы.
                        </Typography>
                        <Typography variant="body1">
                            Информационная система не позволяет специалистам одного учебной организации - получить каким-либо образом информацию других учебных организаций.
                        </Typography>
                    </Box>
                </Container>
            </Box>
            <Box component="footer" sx={{ bgcolor: 'background.paper', py: 2 }}>
                <Container maxWidth="xl">
                    <Stack flexDirection={'row'} justifyContent={'space-between'}>
                        <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                            <KursantIcon width={'125px'} height={'100px'} />
                            <Stack flexDirection={'row'} alignItems={'center'} gap={2} sx={{py: 1}}>
                                <Typography variant={'caption'}>
                                    <a href="/UserOffer.docx" download="Пользовательское соглашение Kursant KZ"
                                       target="_blank"
                                       rel="noreferrer"
                                       style={{
                                           color: '#ce8838',
                                           textDecoration: 'none'
                                       }}
                                    >
                                        Пользовательское соглашение
                                    </a>
                                </Typography>
                            </Stack>
                            <Stack flexDirection={'row'} alignItems={'center'} gap={2} sx={{py: 1}}>
                                <Typography variant={'caption'}>
                                    <a href="/privacy.docx" download="Политика конфиденциальности Kursant KZ"
                                       target="_blank"
                                       rel="noreferrer"
                                       style={{
                                           color: '#ce8838',
                                           textDecoration: 'none'
                                       }}
                                    >
                                        Политика конфиденциальности
                                    </a>
                                </Typography>
                            </Stack>
                        </Stack>
                        <Stack>
                            <Stack flexDirection={'row-reverse'} alignItems={'center'} gap={2} sx={{py: 1}}>
                                <Avatar
                                    sx={{color: '#FEFEFE', bgcolor: '#ec9c44', width: '30px', height: '30px'}}
                                >
                                    <LocalPhone fontSize={'small'}/>
                                </Avatar>
                                <Typography variant={'caption'}>
                                    <b>Телефон - 8(776)-168-44-11</b>
                                </Typography>
                            </Stack>
                            <Stack flexDirection={'row-reverse'} alignItems={'center'} gap={2} sx={{py: 1}}>
                                <Avatar
                                    sx={{color: '#FEFEFE', bgcolor: '#ec9c44', width: '30px', height: '30px'}}
                                >
                                    <AccessTime fontSize={'small'}/>
                                </Avatar>
                                <Typography variant={'caption'}>
                                    10:00 - 18:00 (GMT +6)
                                </Typography>
                            </Stack>
                            <Stack flexDirection={'row-reverse'} alignItems={'center'} gap={2} sx={{py: 1}}>
                                <Avatar
                                    sx={{color: '#FEFEFE', bgcolor: '#ec9c44', width: '30px', height: '30px'}}
                                >
                                    <Copyright fontSize={'small'}/>
                                </Avatar>
                                <Typography variant={'caption'}>
                                    "Kursant KZ", 2023 г.
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Container>
            </Box>
        </Box>
    ) : (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <PageHeader/>
            <Box sx={{...wrapperSx, py: 3, background: '#F8F9FB'}} display={'flex'} flexDirection={'column'} gap={2}>
                <Stack flexDirection={'row'} justifyContent={'center'}>
                    <Tabs
                        value={selectedTab}
                        onChange={(e, newValue) => setSelectedTab(newValue)}
                    >
                        <Tab label={'Учебные организации'} value={'school'}/>
                        <Tab label={'Курсанты'} value={'kursant'}/>
                    </Tabs>
                </Stack>

                <Stack>
                    <img
                        src={isMobile ? '/images/mobile-banner-3.png' : '/images/banner.png'}
                        style={{
                            borderRadius: '25px',
                            cursor: 'pointer'
                        }}
                        onClick={() => {navigate('/tests')}}
                    />
                </Stack>

                <Container component="main" maxWidth="xl">

                    <Box id="actions" sx={{ my: isMobile ? 1 : 3}} display={'flex'}
                         flexDirection={isMobile ? 'column-reverse' : 'row'}
                         justifyContent={isMobile ? 'flex-start' : 'space-between'}
                         alignItems={isMobile ? 'center' : ''}
                         gap={isMobile ? 0 : 3}
                    >
                        <Box id="about" sx={{ width: isMobile ? '100%' : '50%' }}>
                            <Typography variant="h4" component="h1" gutterBottom>
                                О системе
                            </Typography>
                            <Typography variant="body1">
                                В информационной системе "Kursant KZ" каждый курсант учебной организации по подготовке водителей транспортных средств может отследить свой прогресс обучения, а также проверить свои теоретические знания путем прохождения пробного экзамена.
                            </Typography>
                            <Stack flexDirection={'row'} alignItems={'center'} gap={2} sx={{py: 1}}>
                                <Avatar
                                    sx={{color: '#163355', bgcolor: '#ec9c44'}}
                                >
                                    <SchoolIcon />
                                </Avatar>
                                <Typography>
                                    Данные по обучению (в каких автошколах, в каких группах обучался курсант)
                                </Typography>
                            </Stack>
                            <Stack flexDirection={'row'} alignItems={'center'} gap={2} sx={{py: 1}}>
                                <Avatar
                                    sx={{color: '#163355', bgcolor: '#ec9c44'}}
                                >
                                    <WorkspacePremium />
                                </Avatar>
                                <Typography>
                                    Данные по свидетельствам (№ свидетельства, дата выдачи, категория)
                                </Typography>
                            </Stack>
                            <Stack flexDirection={'row'} alignItems={'center'} gap={2} sx={{py: 1}}>
                                <Avatar
                                    sx={{color: '#163355', bgcolor: '#ec9c44'}}
                                >
                                    <StorageIcon />
                                </Avatar>
                                <Typography>
                                    Записи на вождение
                                </Typography>
                            </Stack>
                            <Stack flexDirection={'row'} alignItems={'center'} gap={2} sx={{py: 1}}>
                                <Avatar
                                    sx={{color: '#163355', bgcolor: '#ec9c44'}}
                                >
                                    <Quiz />
                                </Avatar>
                                <Typography>
                                    Результаты пробных тестирований
                                </Typography>
                            </Stack>
                        </Box>

                        <ActionButton
                            title={'Войти'}
                            subtitle={'В качестве курсанта'}
                            backgroundImage={'url(/images/login.png)'}
                            onClick={() => {navigate('/auth-kursant')}}
                        />
                    </Box>

                    <Box id="studying" sx={{ my: 4 }}>
                        <Typography variant="h4" component="h1" gutterBottom sx={{textAlign: 'right'}}>
                            Данные по обучению
                        </Typography>
                        <Typography variant="body1" sx={{textAlign: 'right'}}>
                            Курсанты имеют возможность отследить свой статус обучения в группах:
                        </Typography>
                        <Stack flexDirection={'row-reverse'} alignItems={'center'} gap={2} sx={{py: 1}}>
                            <Avatar
                                sx={{color: '#163355', bgcolor: '#ec9c44'}}
                            >
                                <AccessTime />
                            </Avatar>
                            <Typography>
                                Дата начала обучения
                            </Typography>
                        </Stack>
                        <Stack flexDirection={'row-reverse'} alignItems={'center'} gap={2} sx={{py: 1}}>
                            <Avatar
                                sx={{color: '#163355', bgcolor: '#ec9c44'}}
                            >
                                <AccessTime />
                            </Avatar>
                            <Typography>
                                Дата окончания обучения
                            </Typography>
                        </Stack>
                        <Stack flexDirection={'row-reverse'} alignItems={'center'} gap={2} sx={{py: 1}}>
                            <Avatar
                                sx={{color: '#163355', bgcolor: '#ec9c44'}}
                            >
                                <MoreHoriz />
                            </Avatar>
                            <Typography>
                                Текущий статус обучения
                            </Typography>
                        </Stack>

                        <Typography variant="body1" sx={{textAlign: 'right'}}>
                            Все данные обновляются в текущем времени. За актуальность и обновление данных курсанта отвечает учебная организация, в котором курсант проходит обучение.
                        </Typography>
                    </Box>

                    <Box id="certificates" sx={{ my: 4 }}>
                        <Typography variant="h4" component="h1" gutterBottom>
                            Данные по свидетельствам
                        </Typography>
                        <Typography variant="body1">
                            Курсанты имеют возможность отследить свои свидетельства об окончании обучения:
                        </Typography>
                        <Stack flexDirection={'row'} alignItems={'center'} gap={2} sx={{py: 1}}>
                            <Avatar
                                sx={{color: '#163355', bgcolor: '#ec9c44'}}
                            >
                                <InfoIcon />
                            </Avatar>
                            <Typography>
                                Статус сдачи экзаменов
                            </Typography>
                        </Stack>
                        <Stack flexDirection={'row'} alignItems={'center'} gap={2} sx={{py: 1}}>
                            <Avatar
                                sx={{color: '#163355', bgcolor: '#ec9c44'}}
                            >
                                <InfoIcon />
                            </Avatar>
                            <Typography>
                                Номер свидетельства
                            </Typography>
                        </Stack>
                        <Stack flexDirection={'row'} alignItems={'center'} gap={2} sx={{py: 1}}>
                            <Avatar
                                sx={{color: '#163355', bgcolor: '#ec9c44'}}
                            >
                                <InfoIcon />
                            </Avatar>
                            <Typography>
                                Данные о свидетельстве
                            </Typography>
                        </Stack>

                        <Typography variant="body1">
                            Все данные обновляются в текущем времени. За актуальность и обновление данных курсанта отвечает учебная организация, в котором курсант проходит обучение.
                        </Typography>
                    </Box>

                    <Box id="driving" sx={{ my: 4 }}>
                        <Typography variant="h4" component="h1" gutterBottom sx={{textAlign: 'right'}}>
                            Запись на вождение
                        </Typography>
                        <Typography variant="body1" sx={{textAlign: 'right'}}>
                            Курсанты имеют возможность подать заявку на практическое занятие в нашей системе.
                        </Typography>
                        <Typography variant="body1" sx={{textAlign: 'right'}}>
                            Для этого необходимо выбрать учебная организация, в котором обучается курсант/в котором хотел бы получить практическое занятие.
                        </Typography>
                        <Typography variant="body1" sx={{textAlign: 'right'}}>
                            Затем необходимо выбрать мастера обучения вождению/производственного мастера, у которого курсант желает получить занятие.
                        </Typography>
                        <Typography variant="body1" sx={{textAlign: 'right'}}>
                            После подачи заявки - необходимо ожидать ответа Системы на электронную почту, указанную при заявке.
                        </Typography>
                    </Box>

                    <Box id="about" sx={{ my: 4 }}>
                        <Typography variant="h4" component="h1" gutterBottom>
                            Конфиденциальность и безопасность данных
                        </Typography>
                        <Typography variant="body1">
                            Все чувствительные (конфиденциальные и персональные данные учебной организации/курсантов) подвергаются шифрованию перед внесением в базу данных системы.
                        </Typography>
                        <Typography variant="body1">
                            Информационная система не позволяет специалистам одной учебной организации - получить каким-либо образом информацию других учебных организаций.
                        </Typography>
                    </Box>
                </Container>
            </Box>
            <Box component="footer" sx={{ bgcolor: 'background.paper', py: 2 }}>
                <Container maxWidth="xl">
                    <Stack flexDirection={'row'} justifyContent={'space-between'}>
                        <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                            <KursantIcon width={'125px'} height={'100px'} />
                            <Stack flexDirection={'row'} alignItems={'center'} gap={2} sx={{py: 1}}>
                                <Typography variant={'caption'}>
                                    <a href="/UserOffer.docx" download="Пользовательское соглашение Kursant KZ"
                                       target="_blank"
                                       rel="noreferrer"
                                       style={{
                                           color: '#ce8838',
                                           textDecoration: 'none'
                                       }}
                                    >
                                        Пользовательское соглашение
                                    </a>
                                </Typography>
                            </Stack>
                            <Stack flexDirection={'row'} alignItems={'center'} gap={2} sx={{py: 1}}>
                                <Typography variant={'caption'}>
                                    <a href="/privacy.docx" download="Политика конфиденциальности Kursant KZ"
                                       target="_blank"
                                       rel="noreferrer"
                                       style={{
                                           color: '#ce8838',
                                           textDecoration: 'none'
                                       }}
                                    >
                                        Политика конфиденциальности
                                    </a>
                                </Typography>
                            </Stack>
                        </Stack>
                        <Stack>
                            <Stack flexDirection={'row-reverse'} alignItems={'center'} gap={2} sx={{py: 1}}>
                                <Avatar
                                    sx={{color: '#FEFEFE', bgcolor: '#ec9c44', width: '30px', height: '30px'}}
                                >
                                    <LocalPhone fontSize={'small'}/>
                                </Avatar>
                                <Typography variant={'caption'}>
                                    <b>Телефон - 8(776)-168-44-11</b>
                                </Typography>
                            </Stack>
                            <Stack flexDirection={'row-reverse'} alignItems={'center'} gap={2} sx={{py: 1}}>
                                <Avatar
                                    sx={{color: '#FEFEFE', bgcolor: '#ec9c44', width: '30px', height: '30px'}}
                                >
                                    <AccessTime fontSize={'small'}/>
                                </Avatar>
                                <Typography variant={'caption'}>
                                    10:00 - 18:00 (GMT +6)
                                </Typography>
                            </Stack>
                            <Stack flexDirection={'row-reverse'} alignItems={'center'} gap={2} sx={{py: 1}}>
                                <Avatar
                                    sx={{color: '#FEFEFE', bgcolor: '#ec9c44', width: '30px', height: '30px'}}
                                >
                                    <Copyright fontSize={'small'}/>
                                </Avatar>
                                <Typography variant={'caption'} textAlign={'right'}>
                                    ТОО "Global Driving", Kursant KZ, 2023 г.
                                </Typography>
                            </Stack>
                            <Stack flexDirection={'row-reverse'} alignItems={'center'} gap={2} sx={{py: 1}}>
                                <Avatar
                                  sx={{color: '#FEFEFE', bgcolor: '#ec9c44', width: '30px', height: '30px'}}
                                >
                                    <InfoIcon fontSize={'small'}/>
                                </Avatar>
                                <Typography variant={'caption'} textAlign={'right'}>
                                    БИН: 230840010667
                                </Typography>
                            </Stack>
                            <Stack flexDirection={'row-reverse'} alignItems={'center'} gap={2} sx={{py: 1}}>
                                <Avatar
                                  sx={{color: '#FEFEFE', bgcolor: '#ec9c44', width: '30px', height: '30px'}}
                                >
                                    <House fontSize={'small'}/>
                                </Avatar>
                                <Typography variant={'caption'} textAlign={'right'}>
                                    Астана, р. Алматы, ул. Кайрата Рыскулбекова, Дом 27/1
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Container>
            </Box>
        </Box>
    )
};

export default SchoolLandingPage;