import React, {useEffect, useState} from 'react';
import {
    Avatar,
    Box, Button,
    Dialog,
    DialogContent,
    DialogTitle,
    MenuItem,
    Paper,
    Select, SelectChangeEvent,
    Stack,
    SxProps,
    Theme, Tooltip,
    Typography
} from "@mui/material";
import {GroupDto} from "../../actions/group.type";
import {getActiveSchoolTeachers} from "../../../Employees/actions/api";
import {useSchoolStore} from "../../../School/store/SchoolStore";
import {attachTeacherToGroup, detachTeacherFromGroup} from "../../actions/api";
import {useSnackbarStore} from "../../../global/store/SnackbarStore";
import ClearIcon from '@mui/icons-material/Clear';
import { useUserStore } from '../../../global/store/UserStore';

const paperSx: SxProps<Theme> = {
    boxShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 6px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
    padding: 3,
    borderRadius: 4
};

interface TeachersWindowProps {
    groupInfo: GroupDto;
    fetchGroupInfo: () => void;
}

interface TeachersModalProps {
    groupInfo: GroupDto;
    fetchGroupInfo: () => void;
    open: boolean;
    onClose: () => void;
}

const TeachersModal = ({groupInfo, fetchGroupInfo, open, onClose} : TeachersModalProps) => {

    const [teachersList, setTeachersList] = useState<any[]>([]);
    const [teacherId, setTeacherId] = useState<string>('');
    const schoolInfo = useSchoolStore((state) => state);
    const snackbar = useSnackbarStore((state) => state);

    useEffect(() => {
        getActiveSchoolTeachers(Number(schoolInfo.id))
            .then((res) => {
                console.log(res);
                setTeachersList(res.data);
            })
            .catch((err) => {
                snackbar.errorMessage(err.response.data.message);
            })
    }, []);

    const handleSelectChange = (e: SelectChangeEvent) => {
        setTeacherId(e.target.value);
    }

    const handleAttach = () => {

        if (!groupInfo.id || !teacherId) {
            snackbar.errorMessage('Необходимо выбрать преподавателя');
            return;
        }

        const teacherDto = {
            group_id: groupInfo.id,
            teacher_id: Number(teacherId)
        }

        attachTeacherToGroup(teacherDto)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                fetchGroupInfo();
            })
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>
                Прикрепить преподавателя к группе <i><b>{groupInfo.title}</b></i>
            </DialogTitle>
            <DialogContent>
                <Stack gap={2}>
                    <Select
                        onChange={handleSelectChange}
                        value={teacherId}
                    >
                        {teachersList?.map((teacher) => {
                            return (
                                <MenuItem value={teacher.id} key={teacher.id}>
                                    <Stack sx={{width: '100%'}} flexDirection="row" alignItems={'center'}>
                                        <Avatar
                                            sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                                        >
                                            {teacher.last_name[0]}{teacher.first_name[0]}
                                        </Avatar>
                                        <Stack sx={{width: '100%'}}>
                                            <Stack flexDirection="row" alignItems="center" justifyContent="space-between"
                                                   sx={{width: '100%'}}>
                                                <Typography>{teacher.last_name} {teacher.first_name}</Typography>
                                                <Typography variant="body2" color="gray">#{teacher.id}</Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </MenuItem>
                            )
                        })}
                    </Select>

                    <Typography variant="body2" sx={{mt: 1}}>
                        {'Примечание: в списке преподавателей отображаются только те люди, которые прикреплены к этому учебной организации как преподаватели. Чтобы добавить нового человека, сперва прикрепите его к учебной организации.'}
                    </Typography>

                    <Button onClick={handleAttach}>
                        Прикрепить преподавателя
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}

const TeachersWindow = ({groupInfo, fetchGroupInfo} : TeachersWindowProps) => {

    const isAdmin = useUserStore((state) => state.isAdmin);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

    const handleDetach = () => {
        const teacherDto = {
            teacher_id: groupInfo.teacher.teacher.id,
            group_id: groupInfo.id
        }

        detachTeacherFromGroup(teacherDto)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                fetchGroupInfo();
            })
    }

    return (
        <Box>
            <Paper sx={{...paperSx, mb: 5}}>
                <Typography variant="h4">Преподаватель</Typography>
                {!groupInfo.teacher && (
                    <>
                        <Typography variant="subtitle1" sx={{mb: 1.5}} color="gray">
                            Необходимо прикрепить преподавателя к данной группе
                        </Typography>
                        {(<Button onClick={() => {setIsDialogOpen(true);}}>
                            Выбрать преподавателя
                        </Button>)}
                    </>
                )}
                {groupInfo.teacher && (
                    <>
                        <Typography variant="subtitle1" sx={{mb: 1.5}} color="gray">
                            Преподаватель в данной группе:
                        </Typography>
                        <Stack sx={{ml: 2, width: '400px', p: 2, borderRadius: '10px', background: '#F4F6F8'}} flexDirection="row" alignItems={'center'}>
                            <Avatar
                                sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                            >
                                {groupInfo.teacher.teacher.last_name[0]}{groupInfo.teacher.teacher.first_name[0]}
                            </Avatar>
                            <Stack sx={{width: '100%'}}>
                                <Stack flexDirection="row" alignItems="center" justifyContent="start"
                                       sx={{width: '100%'}}>
                                    <Typography>{groupInfo.teacher.teacher.last_name} {groupInfo.teacher.teacher.first_name}</Typography>
                                    <Typography variant="body2" color="gray" sx={{ml: 1}}>#{groupInfo.teacher.teacher.id}</Typography>
                                </Stack>
                            </Stack>
                            {groupInfo.status === 1 && (<Tooltip title={'Открепить'}>
                                <ClearIcon
                                    sx={[
                                        {ml: 5},
                                        {
                                            '&:hover': {
                                                color: 'red',
                                                cursor: 'pointer'
                                            },
                                        },
                                    ]}
                                    onClick={handleDetach}
                                />
                            </Tooltip>)}
                        </Stack>
                    </>
                )}
            </Paper>

            <TeachersModal
                open={isDialogOpen}
                groupInfo={groupInfo}
                fetchGroupInfo={fetchGroupInfo}
                onClose={() => {setIsDialogOpen(false)}}
            />
        </Box>
    );
};

export default TeachersWindow;