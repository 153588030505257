import React, {useState} from 'react';
import { Box, Button, CircularProgress, Divider, InputBase, Stack, Typography } from '@mui/material';
import '../styles/AuthPage.scss';
import {useSnackbarStore} from "../../global/store/SnackbarStore";
import {Link, useNavigate} from "react-router-dom";
import {useUserStore} from "../../global/store/UserStore";
import Cookies from "js-cookie";
import {KursantLoginDto} from "../dto/AuthDto";
import PageHeader from "../../global/components/PageHeader";
import {Logout} from "@mui/icons-material";
import { useTranslation } from 'react-i18next';

const inputStyle = { WebkitBoxShadow: "0 0 0 1000px white inset" };

const KursantAuthPage = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    const snackbar = useSnackbarStore((state) => state);
    const currentUser = useUserStore((state) => state);

    const [authForm, setAuthForm] = useState<KursantLoginDto>({
        iin: '',
        last_name: ''
    });
    const [authFormLoading, setAuthFormLoading] = useState<boolean>(false);
    const notificationMessage = useSnackbarStore((state) => state.showMessage);
    const setUserInfo = useUserStore((state) => state.setUserInfo);
    const setIsKursant = useUserStore((state) => state.setIsKursant);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        const name = e.target.name;

        if (name === 'iin') {
            if (!checkIINValue(value)) {
                snackbar.errorMessage('Необходимо вводить только числовые значения в поле ИИН');
                return;
            }

            if (!checkIINLength(value)) {
                snackbar.errorMessage('Длина ИИН должна быть 12 символов');
                return;
            }
        }

        setAuthForm((prev) => {
            return {
                ...prev,
                [name]: value,
            }
        });
    };

    const checkIINValue = (value: string) => {
        const onlyDigitsRegex = /^\d+$/;

        if (!onlyDigitsRegex.test(value)) {
            return false;
        }
        return true;
    };

    const checkIINLength = (value: string) => {
        if (value.length > 12) {
            return false;
        }
        return true;
    }


    const checkLastNameValue = (value: string) => {

    }

    const authenticate = () => {
        setAuthFormLoading(true);

        if (!authForm.iin) {
            notificationMessage({
                message: 'Необходимо корректно заполнить поле "ИИН"',
                severity: 'error'
            })
            return;
        }

        if (!authForm.last_name) {
            notificationMessage({
                message: 'Необходимо заполнить поле "Фамилия"',
                severity: 'error'
            })
            return;
        }

        if (authForm.iin && authForm.last_name) {
            setUserInfo({
                iin: authForm.iin,
                last_name: authForm.last_name,
            });
            setIsKursant(true);

            navigate('/kursant');
        }
    }

    const logOut = () => {
        currentUser.logOut();
        Cookies.set('accessToken', '');
        Cookies.set('refreshToken', '');
    }


    return (
        <Box className={'auth'} id={'auth'} sx={{p: 5, background: '#F8F9FB'}} flexDirection={'column'} alignItems={'center'}>

            {!currentUser.iin && <PageHeader />}

            <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                <Typography sx={{mt: 3}} variant={'h5'}><b>{t('kursant-auth.header')}</b></Typography>
                <Typography>{t('kursant-auth.subheader')}</Typography>
            </Stack>

            {currentUser.iin && (

                <Stack
                    flexDirection={'column'}
                    sx={{
                        width: '434px',
                        p: '32px',
                        background: '#FFFFFF',
                        borderRadius: '16px',
                        mt: '81px'
                    }}
                >
                    <Typography variant={'h5'}>
                        {t('kursant-auth.authorized-text')}
                    </Typography>

                    <Divider sx={{mt: '24px', mb: '24px'}}/>

                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={logOut}
                        sx={{height: '56px'}}
                    >
                        <Logout sx={{mr: 1}}/>
                        {t('kursant-auth.logout-btn')}
                    </Button>

                </Stack>
            )}

            {!currentUser.iin && (
                <Stack
                    flexDirection={'column'}
                    sx={{
                        width: '434px',
                        p: '32px',
                        background: '#FFFFFF',
                        borderRadius: '16px',
                        mt: '81px'
                    }}
                >
                    <Typography variant={'h5'} >{t('kursant-auth.title')}</Typography>

                    <Stack flexDirection={'column'} gap={'16px'} sx={{mt: '24px', mb: '24px'}}>
                        <InputBase
                            type={'text'}
                            placeholder={t('kursant-auth.iin')}
                            value={authForm.iin}
                            name={'iin'}
                            onChange={handleInputChange}
                        />

                        <InputBase
                            type={'text'}
                            placeholder={t('kursant-auth.last_name')}
                            value={authForm.last_name}
                            name={'last_name'}
                            onChange={handleInputChange}
                        />
                    </Stack>

                    {!authFormLoading && <Button
                        variant={'contained'}
                        onClick={() => {authenticate()}}
                        disabled={authFormLoading}
                        sx={{height: '56px'}}
                    >
                        {t('kursant-auth.login-btn')}
                    </Button>}

                    {authFormLoading && <CircularProgress sx={{alignSelf: 'center'}}/>}

                    <Link to={'/auth'} style={{marginTop: '24px', display: 'flex', justifyContent: 'center'}}>
                        <Typography>Войти как специалист учебной организации</Typography>
                    </Link>

                    <Divider sx={{mt: '24px', mb: '24px'}}/>

                    {!authFormLoading && <a href="/UserOffer.docx" download="Пользовательское соглашение Kursant KZ"
                                            target="_blank"
                                            rel="noreferrer" style={{width: '100%'}}>
                        <Button
                            variant={'contained'}
                            sx={{height: '56px', width: '100%'}}
                            color={'primary'}
                            disabled={authFormLoading}
                        >
                            Пользовательское соглашение
                        </Button>
                    </a>}

                    {authFormLoading &&
                        <Button
                            variant={'outlined'}
                            sx={{height: '56px', width: '100%'}}
                            color={'info'}
                            disabled={authFormLoading}
                        >
                            {t('kursant-auth.register-btn')}
                        </Button>}

                </Stack>
            )}

        </Box>
    );
};

export default KursantAuthPage;