import React, {ChangeEvent, useEffect, useState} from 'react';
import {
    Box, Button, Dialog, DialogContent, DialogTitle, Stack, Tab, Tabs, Typography
} from '@mui/material';

import '../styles/RegisterPage.scss';
import {
    IRegisterAttributes,
    IRegisterSchool,
    IRegisterUser,
    RegisterEmployeeDto,
    RegisterTransportDto
} from "../dto/AuthDto";
import {useSnackbarStore} from "../../global/store/SnackbarStore";
import { getLocalitiesByRegionId, getRegions, register, registerByAdmin } from '../actions/api';
import PageHeader from "../../global/components/PageHeader";
import { FirstStep } from '../components/FirstStep';
import { ThirdStep } from '../components/ThirdStep';
import { useUserStore } from '../../global/store/UserStore';
import { Link, useNavigate } from 'react-router-dom';
import FourthStep from '../components/FourthStep';
import {Looks3, Looks4, LooksOne, LooksTwo} from '@mui/icons-material';
import SecondStep from "../components/SecondStep";

const RegisterPage = () => {

    const navigate = useNavigate();
    const snackbar = useSnackbarStore((state) => state);
    const currentUser = useUserStore((state) => state);
    const [schoolForm, setSchoolForm] = useState<IRegisterSchool>({
        bin: '',
        title: '',
        director: '',
        director_iin: '',
        email: '',
        region: '',
        locality: '',
        address: '',
        phone1: '',
        phone2: '',
        type: 'legal',
        categories: []
    });
    const [userForm, setUserForm] = useState<IRegisterUser>({
        iin: '',
        gender: 0,
        first_name: '',
        last_name: '',
        patronymic: '',
        email: '',
        phone: '',
        address: '',
        password: ''
    })
    const [attributesForm, setAttributesForm] = useState<IRegisterAttributes>({
        teachers: [{ iin: '', qualification_certificate: '' }],
        drivers: [{ iin: '', qualification_certificate: '' }],
        masters: [{ iin: '', qualification_certificate: '' }],
        transports: [{ plate_number: '', description: '' }],
    })
    const [uploadedImages, setUploadedImages] = useState<File[]>([]);
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const [registerLoading, setRegisterLoading] = useState<boolean>(false);
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [regionsArray, setRegionsArray] = useState<any[]>([]);
    const [localitiesArray, setLocalitiesArray] = useState<any[]>([]);
    const [registerFinished, setRegisterFinished] = useState<boolean>(false);
    const [signString, setSignString] = useState<string>('');

    useEffect(() => {
        getRegions()
            .then((res) => {
                setRegionsArray(res.data);
            })
    }, [])

    const handleSchoolChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        const name = e.target.name;

        setSchoolForm((prev) => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const handleUserChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        const name = e.target.name;

        setUserForm((prev) => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const handleCategoriesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        const name = e.target.name;

        if (checked) {
            const newCategories = schoolForm.categories.concat(name);

            setSchoolForm((prev) => {
                return {
                    ...prev,
                    categories: newCategories
                }
            })
        }

        if (!checked) {
            const isIncluded = schoolForm.categories.includes(name);

            if (isIncluded) {
                const newCategories : string[] = [];

                for (let i = 0; i < schoolForm.categories.length; i++) {
                    const element = schoolForm.categories[i];

                    if (element !== name) {
                        newCategories.push(element);
                    }
                }

                setSchoolForm((prev) => {
                    return {
                        ...prev,
                        categories: newCategories
                    }
                })
            }
        }
    }

    const handleAttachedFiles = (files: File[]) => {
        setUploadedFiles(files);
    }

    const handleImageEvent = (e: ChangeEvent<HTMLInputElement>) => {
        const chosenFiles = Array.prototype.slice.call(e.target.files);
        handleAttachedImages(chosenFiles);
    }

    const handleAttachedImages = (files: File[]) => {
        const uploaded = [...uploadedImages];

        if ((files.length + uploaded.length) > 5) {
            snackbar.errorMessage('Максимально допустимое количество файлов - 5');
            return;
        }
        files.forEach((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
            }
        })
        setUploadedImages(uploaded);
    }

    const finishReg = () => {

        if (registerLoading) {
            return;
        }

        const formData = new FormData();

        const schoolInfo = {
            ...schoolForm,
        }

        const userInfo = {
            ...userForm
        }
        const attributesInfo = {
            ...attributesForm
        }

        if (schoolInfo.locality === 'Другое (вписать в адрес)') {
            formData.set('school', JSON.stringify({...schoolInfo, locality: 'another_locality'}));
        } else {
            formData.set('school', JSON.stringify(schoolInfo));
        }
        formData.set('user', JSON.stringify(userInfo));
        formData.set('attributes', JSON.stringify(attributesInfo))
        formData.set('signData', signString);

        if (uploadedImages.length > 0) {
            for (let i = 0; i < uploadedImages.length; i++) {
                const image = uploadedImages[i];
                formData.append('img', image)
            }
        }

        if (uploadedFiles.length > 0) {
            for (let i = 0; i < uploadedFiles.length; i++) {
                const file = uploadedFiles[i];
                formData.append('file', file)
            }
        }

        if (currentUser.iin && currentUser.isAdmin) {
            setRegisterLoading(true);
            registerByAdmin(formData)
                .then(() => {
                    snackbar.infoMessage(`Автошкола ${schoolInfo.title} зарегистрирована. Аккаунт активен.`);
                    setCurrentStep(0);
                    cleanInputs();
                    setRegisterFinished(true);
                })
                .catch((e) => {
                    const message = e.response.data.message;
                    snackbar.errorMessage(message);
                })
                .finally(() => {
                    setRegisterLoading(false);
                    return;
                })
        } else {
            setRegisterLoading(true);
            register(formData)
                .then(() => {
                    snackbar.infoMessage('Заявка на регистрацию успешно подана. Ожидайте ответа по электронной почте.')
                    setCurrentStep(0);
                    cleanInputs();
                    setRegisterFinished(true);
                })
                .catch((e) => {
                    const message = e.response.data.message;
                    snackbar.errorMessage(message);
                })
                .finally(() => {
                    setRegisterLoading(false);
                })
        }
    };

    const cleanInputs = () => {
        setSchoolForm({
            bin: '',
            title: '',
            director: '',
            director_iin: '',
            email: '',
            region: '',
            locality: '',
            address: '',
            phone1: '',
            phone2: '',
            type: 'legal',
            categories: []
        });

        setUserForm({
            iin: '',
            gender: 0,
            first_name: '',
            last_name: '',
            patronymic: '',
            email: '',
            phone: '',
            address: '',
            password: ''
        })

        setUploadedImages([]);
        setUploadedFiles([]);
    }

    const pageObj = [
        {
            path: '/',
            title: 'Главная'
        },
        {
            path: '/register',
            title: 'Регистрация'
        }
    ];

    const checkSchoolForm = () => {

        if (schoolForm.region === 'Алматы' || schoolForm.region === 'Астана' || schoolForm.region === 'Шымкент') {

        } else {
            if (!schoolForm.locality) {
                return false;
            }
        }

        return !(!schoolForm.bin || !schoolForm.phone1 || !schoolForm.title || !schoolForm.director || !schoolForm.region || !schoolForm.address);
    }

    const checkUserForm = () => {
        return !(!userForm.iin || !userForm.email || !userForm.phone || !userForm.last_name || !userForm.first_name);
    }

    const nextStep = (step: number) => {

        if (step === 1) {
            const isSchoolValid = checkSchoolForm();
            if (!isSchoolValid) {
                snackbar.errorMessage('Необходимо заполнить все поля и выбрать категории');
                return;
            }
        }

        if (step === 2) {
            const isUserValid = checkUserForm();
            if (!isUserValid) {
                snackbar.errorMessage('Необходимо заполнить все поля');
                return;
            }
        }

        setCurrentStep(step);
    }

    const setRegion = (chosenRegion: any) => {
        setSchoolForm((prev) => {
            return {
                ...prev,
                region: chosenRegion
            }
        })

        let regionId: number;

        regionsArray.map((region) => {
            if (region.name_ru === chosenRegion) {
                regionId = region.id
                getLocalitiesByRegionId(regionId)
                    .then((res) => {
                        if (regionId === 1 || regionId === 2 || regionId === 3) {
                            setLocalitiesArray(res.data);
                        } else {
                            setLocalitiesArray([
                                ...res.data,
                                {
                                    id: 50000,
                                    name_ru: 'Другое (вписать в адрес)'
                                }
                            ])
                        }
                    })
            }
        })
    }

    const setLocality = (chosenLocality: any) => {

        if (chosenLocality === 'Другое (вписать в адрес)') {
            setSchoolForm((prev) => {
                return {
                    ...prev,
                    locality: 'Другое (вписать в адрес)'
                }
            })
            return;
        }

        setSchoolForm((prev) => {
            return {
                ...prev,
                locality: chosenLocality
            }
        })
    }

    const setType = (chosenType: any) => {
        setSchoolForm((prev) => {
            return {
                ...prev,
                type: chosenType
            }
        })
    }

    const detach = (type: string, index: number) => {
        if (type === 'image') {
            const filteredImages = uploadedImages.filter((image, arrIndex) => {
                return index !== arrIndex;
            });
            setUploadedImages(filteredImages);
        }

        if (type === 'file') {
            const filteredFiles = uploadedFiles.filter((file, arrIndex) => {
                return index !== arrIndex;
            });
            setUploadedFiles(filteredFiles);
        }
    }

    const handleSign = (signStr: string) => {
        setSignString(signStr);
    }

    return (
        <Box className={'register'} id={'register'} sx={{p: 5, background: '#F8F9FB'}}>
            <PageHeader pages={pageObj} />
            <Stack flexDirection={'row'} justifyContent={'center'} sx={{mt: '21px'}}>
                {currentStep === 0 && (<Typography variant={'h6'}>Регистрация учебной организации</Typography>)}
                {currentStep === 1 && (<Typography variant={'h6'}>Регистрация сотрудников и ТС учебной организации ответственного за регистрацию групп в ИС Курсант</Typography>)}
                {currentStep === 2 && (<Typography variant={'h6'}>Регистрация специалиста учебной организации ответственного за регистрацию групп в ИС Курсант</Typography>)}
                {currentStep === 3 && (<Typography variant={'h6'}>Подпись ЭЦП</Typography>)}
            </Stack>

            <Stack>
                <Link to={'/landing'} style={{width: 'fit-content', color: 'grey'}}>Вернуться на главную страницу</Link>
            </Stack>
            <Tabs
                sx={{width: '100%'}}
                variant={'fullWidth'}
                value={currentStep}
            >
                <Tab label={<LooksOne color={'primary'}/>} value={0} sx={{width: '50%'}} disabled={true}/>
                <Tab label={currentStep === 1 || currentStep === 2 ? <LooksTwo color={'primary'}/> : <LooksTwo/>} value={1} sx={{width: '50%'}} disabled={true}/>
                <Tab label={currentStep === 2 ? <Looks3 color={'primary'}/> : <Looks3/>} value={2} sx={{width: '50%'}} disabled={true}/>
                <Tab label={currentStep === 3 ? <Looks4 color={'primary'}/> : <Looks4/>} value={3} sx={{width: '25%'}} disabled={true}/>
            </Tabs>
            {currentStep === 0 && (
                <FirstStep
                    schoolForm={schoolForm}
                    handleSchoolChange={handleSchoolChange}
                    registerLoading={registerLoading}
                    uploadedImages={uploadedImages}
                    uploadedFiles={uploadedFiles}
                    handleImageEvent={handleImageEvent}
                    handleFileEvent={handleAttachedFiles}
                    handleCategoriesChange={handleCategoriesChange}
                    nextStep={nextStep}
                    detach={detach}
                    regionsArray={regionsArray}
                    setRegion={setRegion}
                    localitiesArray={localitiesArray}
                    setLocality={setLocality}
                    typesArray={[{name: 'Физическое лицо', value: 'natural'}, {name: 'Юридическое лицо', value: 'legal'}]}
                    setType={setType}
                />
            )}
            {currentStep === 1 && (
                <SecondStep
                    attributesForm={attributesForm}
                    setAttributesForm={setAttributesForm}
                    nextStep={setCurrentStep}
                />
            )}
            {currentStep === 2 && (
                <ThirdStep
                    userForm={userForm}
                    handleUserChange={handleUserChange}
                    registerLoading={registerLoading}
                    nextStep={nextStep}
                />
            )}
            {currentStep === 3 && (
                <FourthStep
                  signString={signString}
                  setSignString={handleSign}
                  registerLoading={registerLoading}
                  finishReg={finishReg}
                  nextStep={nextStep}
                  registerFinished={registerFinished}
                  userForm={userForm}
                  schoolForm={schoolForm}
                  attributesForm={attributesForm}
                />
            )}
            <Dialog
              open={registerFinished}
            >
                <DialogTitle>
                    <Typography><b>Заявка на регистрацию успешно подана</b></Typography>
                </DialogTitle>
                <DialogContent>
                    <Stack gap={'10px'}>
                        <Typography>
                            Ожидайте одобрения регистрации модерацией системы в течение рабочего дня.
                        </Typography>

                        <Typography>
                            После первичного одобрения регистрации - на вашу почту поступит письмо с ссылкой на завершение регистрации аккаунта.
                        </Typography>

                        <Typography>
                            Пожалуйста, проверяйте также папку "Спам".
                        </Typography>

                        <Button
                          onClick={() => {navigate('/')}}
                          variant={'outlined'}
                          color={'info'}
                        >
                            Перейти на главную страницу
                        </Button>
                    </Stack>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default RegisterPage;