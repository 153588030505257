import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    FormGroup, InputBase,
    Stack, Tab, Tabs, Typography
} from "@mui/material";
import {
    approveAppealApi,
    changeEmailByAdmin,
    denyAppeal,
    getAppealById,
    resendEmail
} from '../actions/api';
import { useSnackbarStore } from '../../global/store/SnackbarStore';
import EmailIcon from '@mui/icons-material/Email';

interface AppealModalProps {
    modalOpen: boolean;
    onClose: () => void;
    appealId: number | null;
    updateAppeals: (newTab: number) => void;
    tab: number;
}

const availableCategories = ['A', 'A1', 'B', 'B1', 'C', 'C1', 'D', 'D1', 'CE', 'C1E', 'DE', 'D1E', 'BE', 'BC1', 'Tm', 'Tb'];

export enum AppealStatus {
    'Новая' = 1,
    'Ожидает подтверждения по e-mail' = 2,
    'Зарегистрирована' = 3,
    'Denied' = 10
}

type AppealInfoType = 'school' | 'employees' | 'transports';

const AppealModal = ({modalOpen, onClose, appealId, updateAppeals, tab}: AppealModalProps) => {

    const snackbar = useSnackbarStore((store) => store);
    const [appealInfo, setAppealInfo] = useState<any>({
        id: 1,
        school_info: {
            bin: "",
            title: "",
            phone1: "",
            phone2: "",
            address: "",
            director: "",
            director_iin: "",
            categories: [],
        },
        user_info: {
            iin: "",
            email: "",
            phone: "",
            gender: 0,
            address: "",
            password: "",
            last_name: "",
            first_name: "",
            patronymic: ""
        },
        attributes_info: {
            teachers: [],
            drivers: [],
            masters: [],
            transports: []
        },
        img: [],
        file: [],
        status: 0
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [approveLoading, setApproveLoading] = useState<boolean>(false);
    const [denyLoading, setDenyLoading] = useState<boolean>(false);
    const [categories, setCategories] = useState<string[]>([]);
    const [newEmail, setNewEmail] = useState<string>('');
    const [currentTab, setCurrentTab] = useState<AppealInfoType>('school');

    const errorMessage = useSnackbarStore((state) => state.errorMessage);
    const successMessage = useSnackbarStore((state) => state.successMessage);

    useEffect(() => {
        setLoading(true);

        if (typeof appealId === 'number') {
            fetchAppealInfo(appealId);
        }

    }, [appealId]);

    const fetchAppealInfo = (appealId: number) => {
        getAppealById(appealId)
            .then((res) => {
                setAppealInfo(res.data);
                setCategories(res.data.school_info.categories);
                setLoading(false);
            })
            .catch((e) => {
                snackbar.errorMessage(e.response.data.message ? e.response.data.message : 'Произошла непредвиденная ошибка')
            })
    }

    const declineAppeal = () => {
        const appealId = appealInfo.id;
        setDenyLoading(true);
        denyAppeal(appealId)
            .then(() => {
                successMessage('Заявка успешно удалена');
                onClose();
                updateAppeals(tab);
            })
            .catch((e) => {
                errorMessage(e.response.data.message);
            })
            .finally(() => {
                setDenyLoading(false);
            })
    };

    const approveAppeal = () => {
        const appealId = appealInfo.id;
        setApproveLoading(true);
        approveAppealApi(appealId)
            .then(() => {
                successMessage('Заявка успешно одобрена');
                fetchAppealInfo(appealId);
                onClose();
                updateAppeals(tab);
            })
            .catch((e) => {
                errorMessage(e.response.data.message);
            })
            .finally(() => {
                setApproveLoading(false);
            })
    };

    const resendConfirm = () => {
        setDenyLoading(true);
        resendEmail({email: appealInfo.user_info.email})
            .then(() => {
                snackbar.successMessage(`Письмо успешно переотправлено на почту ${appealInfo.user_info.email}`);
            })
            .catch((err) => {
                snackbar.errorMessage(err.response.data.message);
            })
            .finally(() => {
                setDenyLoading(false);
            })
    }

    const changeEmail = () => {
        if (!newEmail) {
            snackbar.errorMessage('Необходимо ввести новый email');
            return;
        }
        setApproveLoading(true)
        changeEmailByAdmin(newEmail, appealInfo.id)

            .then(() => {
                snackbar.successMessage(`Электронная почта была изменена. Также было послано новое письмо с подтверждением регистрации`);
                fetchAppealInfo(appealInfo.id);
            })
            .catch((err) => {
                snackbar.errorMessage(err.response.data.message);
            })
            .finally(() => {
                setApproveLoading(false);
            })
    };

    const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setNewEmail(e.target.value);
    };

    const SchoolTab = () => {
        return (
            <>
                <section className={'appeal-school'}>
                    <h4>
                        Данные учебного центра
                    </h4>
                    <p>
                        <span className={'appeal-label'}>БИН: </span>
                        <span className={'appeal-value'}>{appealInfo.school_info.bin}</span>
                    </p>
                    <Divider/>
                    <p>
                        <span className={'appeal-label'}>Название: </span>
                        <span className={'appeal-value'}>{appealInfo.school_info.title}</span>
                    </p>
                    <Divider/>
                    <p>
                        <span className={'appeal-label'}>Электронная почта: </span>
                        <span className={'appeal-value'}>{appealInfo.school_info.email}</span>
                    </p>
                    <Divider/>
                    <p>
                        <span className={'appeal-label'}>Основной телефон: </span>
                        <span className={'appeal-value'}>{appealInfo.school_info.phone1}</span>
                    </p>
                    <Divider/>
                    <p>
                        <span className={'appeal-label'}>Дополнительный телефон: </span>
                        <span className={'appeal-value'}>{appealInfo.school_info.phone2}</span>
                    </p>
                    <Divider/>
                    <p>
                        <span className={'appeal-label'}>Регион: </span>
                        <span className={'appeal-value'}>{appealInfo.school_info.region}</span>
                    </p>
                    <Divider/>
                    <p>
                        <span className={'appeal-label'}>Населенный пункт: </span>
                        <span className={'appeal-value'}>{appealInfo.school_info.locality}</span>
                    </p>
                    <Divider/>
                    <p>
                        <span className={'appeal-label'}>Адрес: </span>
                        <span className={'appeal-value'}>{appealInfo.school_info.address}</span>
                    </p>
                    <Divider/>
                    <p>
                        <span className={'appeal-label'}>Ф.И.О. директора: </span>
                        <span className={'appeal-value'}>{appealInfo.school_info.director}</span>
                    </p>
                    <Divider/>
                    <p>
                        <span className={'appeal-label'}>ИИН директора: </span>
                        <span className={'appeal-value'}>{appealInfo.school_info.director_iin}</span>
                    </p>
                </section>
                <section className={'appeal-categories'}>
                    <h4>
                        Категории
                    </h4>
                    <FormGroup>
                        <Stack flexDirection="row" flexWrap="wrap" className={'appeal-categories-ctn'}>
                            {availableCategories.map(categoryId =>
                                <FormControlLabel
                                    key={categoryId} label={categoryId} sx={{mr: 3}}
                                    className={'appeal-category-label'}
                                    control={<Checkbox
                                        checked={categories.includes(categoryId)}
                                        disabled={true}
                                    />}
                                />
                            )}
                        </Stack>
                    </FormGroup>
                </section>
                <section className={'appeal-user'}>
                    <h4>
                        Данные пользователя
                    </h4>
                    <p>
                        <span className={'appeal-label'}>ИИН: </span>
                        <span className={'appeal-value'}>{appealInfo.user_info.iin}</span>
                    </p>
                    <Divider />
                    <p>
                        <span className={'appeal-label'}>Ф.И.О: </span>
                        <span className={'appeal-value'}>{appealInfo.user_info.last_name + ' ' + appealInfo.user_info.first_name + ' ' + appealInfo.user_info.patronymic}</span>
                    </p>
                    <Divider />
                    <p>
                        <span className={'appeal-label'}>Электронная почта: </span>
                        <span className={'appeal-value'}>{appealInfo.user_info.email}</span>
                    </p>
                    <Divider />
                    <p>
                        <span className={'appeal-label'}>Телефон: </span>
                        <span className={'appeal-value'}>{appealInfo.user_info.phone}</span>
                    </p>
                </section>
            </>
        )
    };

    const EmployeesTab = () => {
        return (
            <>
                <section className={'appeal-employees'}>
                    <h4>
                        Сотрудники учебного центра
                    </h4>
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                        }}
                    >
                        Преподаватели
                    </Typography>
                    {appealInfo?.attributes_info?.teachers?.map((teacher: any) => {
                        return (
                            <Stack
                                display={'flex'}
                                flexDirection={'row'}
                                flexWrap={'wrap'}
                                sx={{
                                    background: '#F4F6F8FF',
                                    borderRadius: '10px',
                                    width: 'fit-content',
                                }}
                            >
                                <Box
                                    flexDirection={'column'}
                                    sx={{p: 2}}
                                >
                                    <Typography
                                        sx={{p: 0}}
                                    >
                                        {teacher.last_name} {teacher.first_name}
                                    </Typography>
                                    <Typography
                                        sx={{p: 0}}
                                    >
                                        Серия ВУ: {teacher.driver_license_series}
                                    </Typography>
                                    <Typography
                                        sx={{p: 0}}
                                    >
                                        Номер ВУ: {teacher.driver_license_number}
                                    </Typography>
                                    <Typography
                                        sx={{p: 0}}
                                    >
                                        Номер квалификационного свидетельства: {teacher.qualification_certificate}
                                    </Typography>
                                    <FormGroup>
                                        <Stack
                                            flexDirection="row"
                                            flexWrap="wrap"
                                            className={'appeal-categories-ctn'}
                                            sx={{
                                                maxWidth: '400px'
                                            }}
                                        >
                                            {availableCategories.map(categoryId =>
                                                <FormControlLabel
                                                    key={categoryId} label={categoryId} sx={{mr: 3}}
                                                    className={'appeal-category-label'}
                                                    control={<Checkbox
                                                        checked={teacher.driver_license_categories.includes(categoryId)}
                                                        disabled={true}
                                                    />}
                                                />
                                            )}
                                        </Stack>
                                    </FormGroup>
                                </Box>
                            </Stack>
                        )
                    })}
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                        }}
                    >
                        Мастера обучения вождению
                    </Typography>
                    {appealInfo?.attributes_info?.drivers?.map((driver: any) => {
                        return (
                            <Stack
                                display={'flex'}
                                flexDirection={'row'}
                                flexWrap={'wrap'}
                                sx={{
                                    background: '#F4F6F8FF',
                                    borderRadius: '10px',
                                    width: 'fit-content',
                                }}
                            >
                                <Box
                                    flexDirection={'column'}
                                    sx={{p: 2}}
                                >
                                    <Typography
                                        sx={{p: 0}}
                                    >
                                        {driver.last_name} {driver.first_name}
                                    </Typography>
                                    <Typography
                                        sx={{p: 0}}
                                    >
                                        Серия ВУ: {driver.driver_license_series}
                                    </Typography>
                                    <Typography
                                        sx={{p: 0}}
                                    >
                                        Номер ВУ: {driver.driver_license_number}
                                    </Typography>
                                    <Typography
                                        sx={{p: 0}}
                                    >
                                        Номер квалификационного свидетельства: {driver.qualification_certificate}
                                    </Typography>
                                    <FormGroup>
                                        <Stack
                                            flexDirection="row"
                                            flexWrap="wrap"
                                            className={'appeal-categories-ctn'}
                                            sx={{
                                                maxWidth: '400px'
                                            }}
                                        >
                                            {availableCategories.map(categoryId =>
                                                <FormControlLabel
                                                    key={categoryId} label={categoryId} sx={{mr: 3}}
                                                    className={'appeal-category-label'}
                                                    control={<Checkbox
                                                        checked={driver.driver_license_categories.includes(categoryId)}
                                                        disabled={true}
                                                    />}
                                                />
                                            )}
                                        </Stack>
                                    </FormGroup>
                                </Box>
                            </Stack>
                        )
                    })}
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                        }}
                    >
                        Мастера производственного обучения
                    </Typography>
                    {appealInfo?.attributes_info?.masters?.map((master: any) => {
                        return (
                            <Stack
                                display={'flex'}
                                flexDirection={'row'}
                                flexWrap={'wrap'}
                                sx={{
                                    background: '#F4F6F8FF',
                                    borderRadius: '10px',
                                    width: 'fit-content',
                                }}
                            >
                                <Box
                                    flexDirection={'column'}
                                    sx={{p: 2}}
                                >
                                    <Typography
                                        sx={{p: 0}}
                                    >
                                        {master.last_name} {master.first_name}
                                    </Typography>
                                    <Typography
                                        sx={{p: 0}}
                                    >
                                        Серия ВУ: {master.driver_license_series}
                                    </Typography>
                                    <Typography
                                        sx={{p: 0}}
                                    >
                                        Номер ВУ: {master.driver_license_number}
                                    </Typography>
                                    <Typography
                                        sx={{p: 0}}
                                    >
                                        Номер квалификационного свидетельства: {master.qualification_certificate}
                                    </Typography>
                                    <FormGroup>
                                        <Stack
                                            flexDirection="row"
                                            flexWrap="wrap"
                                            className={'appeal-categories-ctn'}
                                            sx={{
                                                maxWidth: '400px'
                                            }}
                                        >
                                            {availableCategories.map(categoryId =>
                                                <FormControlLabel
                                                    key={categoryId} label={categoryId} sx={{mr: 3}}
                                                    className={'appeal-category-label'}
                                                    control={<Checkbox
                                                        checked={master.driver_license_categories.includes(categoryId)}
                                                        disabled={true}
                                                    />}
                                                />
                                            )}
                                        </Stack>
                                    </FormGroup>
                                </Box>
                            </Stack>
                        )
                    })}
                </section>
            </>
        )
    }

    const TransportsTab = () => {
        return (
            <>
                <section className={'appeal-employees'}>
                    <h4>
                        Сотрудники учебного центра
                    </h4>
                    <Typography
                        sx={{
                            fontWeight: 'bold',
                        }}
                    >
                        Учебные транспортные средства
                    </Typography>
                    {appealInfo?.attributes_info?.transports?.map((transport: any) => {
                        return (
                            <Stack
                                display={'flex'}
                                flexDirection={'row'}
                                flexWrap={'wrap'}
                                sx={{
                                    background: '#F4F6F8FF',
                                    borderRadius: '10px',
                                    width: 'fit-content',
                                }}
                            >
                                <Box
                                    flexDirection={'column'}
                                    sx={{p: 2}}
                                >
                                    <Typography
                                        sx={{p: 0}}
                                    >
                                        {transport.brand} {transport.model}
                                    </Typography>
                                    <Typography
                                        sx={{p: 0}}
                                    >
                                        ГРНЗ: {transport.plate_number}
                                    </Typography>
                                    <Typography
                                        sx={{p: 0}}
                                    >
                                        Описание: {transport.description}
                                    </Typography>
                                    <FormGroup>
                                        <Stack
                                            flexDirection="row"
                                            flexWrap="wrap"
                                            className={'appeal-categories-ctn'}
                                            sx={{
                                                maxWidth: '400px'
                                            }}
                                        >
                                            {availableCategories.map((categoryId) => {
                                                if (categoryId === transport.category) {
                                                    return (
                                                        <FormControlLabel
                                                            key={categoryId} label={categoryId} sx={{mr: 3}}
                                                            className={'appeal-category-label'}
                                                            control={<Checkbox
                                                                checked={true}
                                                                disabled={true}
                                                            />}
                                                        />
                                                    )
                                                }
                                            })}
                                        </Stack>
                                    </FormGroup>
                                </Box>
                            </Stack>
                        )
                    })}
                </section>
            </>
        )
    }

    return (
        <Dialog
            open={modalOpen}
            fullWidth={true}
            maxWidth={'xl'}
            onClose={() => {
                onClose()
            }}
            className={'appeal'}
        >
            <DialogTitle>
                {`Заявка #${appealId}`}
            </DialogTitle>
            <DialogContent>
                {loading && <CircularProgress/>}
                {!loading && (
                    <>
                        <section className={'appeal-status'}>
                            <Typography>
                                Статус заявки - <b>{AppealStatus[appealInfo.status]}</b>
                            </Typography>
                            <Typography>
                                Тип компании - <b>{appealInfo.school_info.type === 'natural' ? 'Индивидуальный предприниматель (ИП)' : 'Товарищество с ограниченной ответственностью (ТОО)'}</b>
                            </Typography>
                        </section>
                        <Tabs
                            value={currentTab}
                            onChange={(event, value) => {setCurrentTab(value)}}
                            sx={{mt: 1}}
                        >
                            <Tab label={'Школа'} value={'school'}/>
                            <Tab label={'Сотрудники'} value={'employees'}/>
                            <Tab label={'Транспорт'} value={'transports'}/>
                        </Tabs>
                        {currentTab === 'school' && <SchoolTab />}
                        {currentTab === 'employees' && <EmployeesTab/>}
                        {currentTab === 'transports' && <TransportsTab/>}

                        {appealInfo.status === 1 && (<section className={'appeal-actions'}>
                            <h4>
                                Действия
                            </h4>
                            <Stack flexDirection="row" flexWrap="wrap">
                                {!denyLoading && (<Button
                                    className={'appeal-actions-decline'}
                                    variant={'contained'}
                                    onClick={declineAppeal}
                                    disabled={approveLoading}
                                >
                                    Отклонить
                                </Button>)}

                                {denyLoading && (<CircularProgress className={'appeal-actions-decline'}/>)}

                                {!approveLoading && (<Button
                                    className={'appeal-actions-accept'}
                                    variant={'contained'}
                                    onClick={approveAppeal}
                                    disabled={denyLoading}
                                >
                                    Принять
                                </Button>)}

                                {approveLoading && (<CircularProgress className={'appeal-actions-accept'}/>)}
                            </Stack>
                        </section>)}

                        {appealInfo.status === 2 && (<section className={'appeal-actions'}>
                            <h4>
                                Действия
                            </h4>
                            <Stack flexDirection="row" flexWrap="wrap">
                                {!denyLoading && (<Button
                                    className={'appeal-actions-decline'}
                                    variant={'contained'}
                                    onClick={resendConfirm}
                                    disabled={approveLoading}
                                >
                                    <EmailIcon style={{marginRight: '5px'}}/> Перепослать письмо с подтверждением
                                </Button>)}

                                {denyLoading && (<CircularProgress className={'appeal-actions-decline'}/>)}

                                {!approveLoading && (
                                    <InputBase
                                        onChange={handleChangeEmail}
                                        value={newEmail}
                                        placeholder={'Новый email'}
                                        sx={{ml: 'auto', mr: '5px'}}
                                    />
                                )}

                                {!approveLoading && (<Button
                                    className={'appeal-actions-change-email'}
                                    variant={'contained'}
                                    onClick={changeEmail}
                                    disabled={denyLoading || !newEmail}
                                    style={{marginLeft: '5px !important'}}
                                >
                                    Сменить email
                                </Button>)}

                                {approveLoading && (<CircularProgress className={'appeal-actions-accept'}/>)}
                            </Stack>
                        </section>)}
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default AppealModal;