import React, { useEffect } from 'react';
import { Avatar, Box, Button, Stack, Typography } from '@mui/material';
import {useNavigate} from "react-router-dom";
import {useSnackbarStore} from "./global/store/SnackbarStore";
import PageHeader from "./global/components/PageHeader";
import {useUserStore} from "./global/store/UserStore";
import {checkAuth, logoutFromSystem} from './global/actions/main.api';
import {useIdleTimer} from "react-idle-timer";
import { wrapperSx } from './global/helpers/globalstyles';
import { getName } from './global/helpers/text';
import { useSchoolStore } from './School/store/SchoolStore';
import SchoolsListComponent from './School/components/SchoolsListComponent';


function App() {

    const navigate = useNavigate();
    const snackbar = useSnackbarStore((state) => state);
    const currentUser = useUserStore((state) => state);
    const currentSchool = useSchoolStore((state) => state);

    const onIdle = () => {
        if (currentUser.iin) {
            navigate('/auth');
            snackbar.infoMessage(`Сессия была прекращена в связи с бездействием.`)
            logoutFromSystem()
        }
    }

    useIdleTimer({
        onIdle,
        timeout: 60000 * 15,
        throttle: 500
    })

    useEffect(() => {
        if (currentUser.isKursant) {
            navigate('/kursant');
            return;
        }

        if (currentUser.iin && !currentUser.isKursant) {
            checkAuth()
                .then((res) => {
                    if (res.data.valid) {
                    } else {
                        currentUser.logOut();
                        snackbar.infoMessage('Прошло много времени с последнего посещения сайта. Необходимо авторизоваться повторно.');
                    }
                })
                .catch((err) => {
                    snackbar.errorMessage(err.response.data.message ? err.response.data.message : 'Произошла ошибка обновления токена. Авторизуйтесь повторно');
                })
        } else {
            navigate('/landing')
        }
    }, []);

    const GuestMenu = () => {
        return (
            <Stack direction={'column'} width={'500px'}>
                <Button
                    sx={{p: 2, mt: 2}}
                    variant={'contained'}
                    onClick={() => {navigate('/auth')}}
                >
                    Войти в систему
                </Button>
                <Button
                    sx={{p: 2, mt: 2}}
                    variant={'contained'}
                    onClick={() => {navigate('/register')}}
                >
                    Зарегистрироваться
                </Button>
            </Stack>
        )
    }

    const UserMenu = () => {

        const name = getName(currentUser);

        return (
            <Stack direction={'column'} width={'100%'} sx={{mt: '38px'}}>
                <Stack flexDirection={'row'} sx={{mb: '38px'}}>
                    <Avatar
                        sx={{width: '57px', height: '57px', mr: 1.5, fontSize: 24, color: '#99999F', bgcolor: '#D9E8FF'}}
                    >
                        {name.partialName}
                    </Avatar>

                    <Stack flexDirection={'column'} justifyContent={'flex-start'}>
                        <Typography sx={{fontSize: '16px'}}>{name.fullName}</Typography>

                        <Typography sx={{mt: 'auto', fontSize: '12px'}}>Учебная организация: {currentSchool.title}</Typography>
                        <Typography sx={{fontSize: '12px'}}><b>ID:{' '}</b>{currentUser.id}</Typography>
                    </Stack>


                    <Button
                        sx={{p: 2, maxWidth: '250px', ml: 'auto'}}
                        variant={'outlined'}
                        onClick={() => {navigate(`/change-password/${currentUser.id}`)}}
                        color={'info'}
                    >
                        Сменить пароль
                    </Button>

                </Stack>

                <SchoolsListComponent withoutSearch={true}/>
            </Stack>
        )
    }

    const AdminMenu = () => {
        const name = getName(currentUser);

        return (
            <Stack direction={'column'} width={'100%'} sx={{mt: '38px', pb: '50px'}}>
                <Stack flexDirection={'row'} sx={{mb: '16px'}}>
                    <Avatar
                        sx={{width: '57px', height: '57px', mr: 1.5, fontSize: 24, color: '#99999F', bgcolor: '#D9E8FF'}}
                    >
                        {name.partialName}
                    </Avatar>

                    <Stack flexDirection={'column'} justifyContent={'flex-start'}>
                        <Typography sx={{fontSize: '16px'}}>{name.fullName}</Typography>

                        <Typography sx={{mt: 'auto', fontSize: '12px'}}>Роль: Администратор</Typography>
                        <Typography sx={{fontSize: '12px'}}><b>ID:{' '}</b>{currentUser.id}</Typography>
                    </Stack>

                    <Button
                        sx={{p: 2, maxWidth: '250px', ml: 'auto'}}
                        variant={'outlined'}
                        onClick={() => {navigate(`/change-password/${currentUser.id}`)}}
                        color={'info'}
                    >
                        Сменить пароль
                    </Button>
                </Stack>

                <Stack direction={'column'} width={'500px'} alignSelf={'center'}>
                    <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                        <Typography sx={{mt: 3}} variant={'h5'}><b>Функции администратора</b></Typography>
                    </Stack>
                    <Button
                        sx={{p: 2, mt: 2}}
                        variant={'contained'}
                        onClick={() => {navigate('/admin')}}
                    >
                        Перейти к заявкам на регистрацию
                    </Button>
                    <Button
                        sx={{p: 2, mt: 2}}
                        variant={'contained'}
                        onClick={() => {navigate('/schools')}}
                    >
                        Перейти к списку учебных организаций
                    </Button>
                    <Button
                        sx={{p: 2, mt: 2}}
                        variant={'contained'}
                        onClick={() => {navigate('/new-school')}}
                    >
                        {currentUser.isAdmin ? 'Добавить автошколу пользователю' : 'Добавить учебную организацию'}
                    </Button>
                    <Button
                        sx={{p: 2, mt: 2}}
                        variant={'contained'}
                        onClick={() => {navigate('/register')}}
                        color={'warning'}
                    >
                        Зарегистрировать автошколу и пользователя
                    </Button>
                    <Button
                        sx={{p: 2, mt: 2}}
                        variant={'contained'}
                        onClick={() => {navigate('/change-password')}}
                        color={'warning'}
                    >
                        Сменить пароль пользователю
                    </Button>
                    <Button
                        sx={{p: 2, mt: 2}}
                        color={'secondary'}
                        variant={'contained'}
                        onClick={() => {navigate('/admin/tickets')}}
                    >
                        Перейти в службу поддержки
                    </Button>
                    <Button
                        sx={{p: 2, mt: 2}}
                        color={'secondary'}
                        variant={'contained'}
                        onClick={() => {navigate('/journal')}}
                    >
                        Журнал авторизаций
                    </Button>
                    <Button
                        sx={{p: 2, mt: 2}}
                        color={'secondary'}
                        variant={'contained'}
                        onClick={() => {navigate('/statistic')}}
                    >
                        Статистика по учебным организациям
                    </Button>
                    <Button
                        sx={{p: 2, mt: 2}}
                        color={'secondary'}
                        variant={'contained'}
                        onClick={() => {navigate('/admin/notifications')}}
                    >
                        Создать уведомление
                    </Button>
                    <Button
                        sx={{p: 2, mt: 2}}
                        color={'secondary'}
                        variant={'contained'}
                        onClick={() => {navigate('/admin/corrections')}}
                    >
                        Корректировки по курсантам
                    </Button>
                    <Button
                        sx={{p: 2, mt: 2}}
                        color={'secondary'}
                        variant={'contained'}
                        onClick={() => {navigate('/admin/corrections-invoices')}}
                    >
                        Счета на оплату (корректировки)
                    </Button>
                    <Button
                        sx={{p: 2, mt: 2}}
                        color={'secondary'}
                        variant={'contained'}
                        onClick={() => {navigate('/questions')}}
                    >
                        База вопросов
                    </Button>
                </Stack>

            </Stack>
        )
    }

    return (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <PageHeader />
            <Box sx={{...wrapperSx}}>
                {!currentUser.iin && <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                    <Typography sx={{mt: 3}} variant={'h5'}><b>Информационная система</b></Typography>
                    <Typography variant={'h6'}>Для учета групп учебной организации по подготовке водителей транспортных
                        средств Республики Казахстан</Typography>
                </Stack>}

                {currentUser.iin && !currentUser.isKursant && <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                    <Typography sx={{mt: 3}} variant={'h5'}><b>Добро пожаловать в систему, {currentUser.first_name}!</b></Typography>
                </Stack>}

                {currentUser.iin && !currentUser.isAdmin && (<UserMenu />)}

                <Stack direction={'column'} sx={{alignItems: 'center', mt: 5}}>
                    {!currentUser.iin && (<GuestMenu />)}
                    {currentUser.iin && currentUser.isAdmin && (<AdminMenu />)}
                </Stack>
            </Box>
        </Box>
    );
}

export default App;
