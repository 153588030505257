import React, {useState} from 'react';
import {GroupDto} from "../../actions/group.type";
import {Button, CircularProgress, Dialog, DialogContent, DialogTitle, TextField} from "@mui/material";
import {StudentAddDto} from "../../actions/student.type";
import {addStudentToGroup} from "../../actions/api";
import {useSnackbarStore} from "../../../global/store/SnackbarStore";
import {validateIIN} from "../../../global/helpers/validators";

interface ModalProps {
    groupInfo: GroupDto,
    open: boolean,
    onClose: () => void,
    fetchGroupInfo: () => void
}

const AddStudentModal = ({groupInfo, open, onClose, fetchGroupInfo} : ModalProps) => {

    const snackbar = useSnackbarStore((state) => state);
    const [studentForm, setStudentForm] = useState<StudentAddDto>({
        iin: '',
        first_name: '',
        last_name: '',
        patronymic: '',
        medical_certificate_number: '',
        medical_center_title: '',
        medical_center_bin: '',
        medical_examination_date: '',
        passed: null,
        group_id: groupInfo.id
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [iinValid, setIinValid] = useState<boolean>(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const name = e.target.name;
        const value = e.target.value;

        setStudentForm((prev) => {
            return {
                ...prev,
                [name]: value
            }
        })
    };

    const handleIINChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const name = e.target.name;
        const value = e.target.value;

        if (value.includes(' ')) {
            snackbar.errorMessage('ИИН должен состоять только из цифр');
            return;
        }

        if (!/^\d+$/.test(value) && value !== "") {
            snackbar.errorMessage('ИИН должен содержать только цифры');
            return;
        }

        if (value.length > 12) {
            snackbar.errorMessage('ИИН должен состоять из 12 цифр');
            return;
        }

        const isValid = validateIIN(value);

        setIinValid(isValid);

        setStudentForm((prev) => {
            return {
                ...prev,
                [name]: value
            }
        })
    };

    const clearForm = () => {
        setStudentForm({
            iin: '',
            first_name: '',
            last_name: '',
            patronymic: '',
            medical_certificate_number: '',
            medical_center_title: '',
            medical_center_bin: '',
            medical_examination_date: '',
            passed: null,
            group_id: groupInfo.id,
        })
    }

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!iinValid) {
            snackbar.errorMessage('Некорректный ИИН');
            return;
        }

        setLoading(true);
        addStudentToGroup(studentForm)
            .then((res) => {
                snackbar.successMessage(`Курсант успешно добавлен в группу`);
                clearForm();
            })
            .catch((err) => {
                snackbar.errorMessage(err.response.data.message);
            })
            .finally(() => {
                setLoading(false);
                fetchGroupInfo();
            })
    }

    return (
        <Dialog
            maxWidth={'xl'}
            fullWidth={true}
            open={open}
            onClose={onClose}
        >
            <DialogTitle>
                Добавить курсанта в группу <b>{groupInfo.title}</b>
            </DialogTitle>
            <DialogContent>
                <form
                    style={{display: 'flex', flexDirection: 'column', gap: '10px', padding: '20px'}}
                    onSubmit={handleSubmit}
                >
                    <TextField
                        name={'iin'}
                        value={studentForm.iin}
                        onChange={handleIINChange}
                        label={'ИИН'}
                        required={true}
                        color={iinValid ? 'primary' : 'error'}
                        helperText={iinValid ? '' : 'Некорректный ИИН'}
                    />
                    <TextField
                        name={'last_name'}
                        value={studentForm.last_name}
                        onChange={handleChange}
                        label={'Фамилия'}
                        required={true}
                    />
                    <TextField
                        name={'first_name'}
                        value={studentForm.first_name}
                        onChange={handleChange}
                        label={'Имя'}
                        required={true}
                    />
                    <TextField
                        name={'patronymic'}
                        value={studentForm.patronymic}
                        onChange={handleChange}
                        label={'Отчество'}
                    />
                    <TextField
                      name={'medical_certificate_number'}
                      value={studentForm.medical_certificate_number}
                      onChange={handleChange}
                      label={'Номер мед. справки (Необязательно)'}
                    />
                    <TextField
                      name={'medical_center_title'}
                      value={studentForm.medical_center_title}
                      onChange={handleChange}
                      label={'Название мед. центра (Необязательно)'}
                    />
                    <TextField
                      name={'medical_center_bin'}
                      value={studentForm.medical_center_bin}
                      onChange={handleChange}
                      label={'БИН мед. центра (Необязательно)'}
                    />
                    <TextField
                      name={'medical_examination_date'}
                      type={'date'}
                      value={studentForm.medical_examination_date}
                      onChange={handleChange}
                      label={'Дата прохождения мед. осмотра (Необязательно)'}
                    />

                    {!loading && (
                        <Button
                            variant={'contained'}
                            type={'submit'}
                        >
                            Добавить
                        </Button>)}
                    {loading && <CircularProgress />}
                </form>
            </DialogContent>
        </Dialog>
    );
};

export default AddStudentModal;