import {Avatar, Box, InputBase, Stack, TextField, Typography} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getUserSchools } from '../actions/api';
import { useSnackbarStore } from '../../global/store/SnackbarStore';
import { DataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid';
import { blue } from '@mui/material/colors';
import { DirectionsCar } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { useUserStore } from '../../global/store/UserStore';
import { getName } from '../../global/helpers/text';

interface SchoolsListComponentProps {
    withoutSearch?: boolean
}

const SchoolsListComponent = ({withoutSearch}: SchoolsListComponentProps) => {

    const navigate = useNavigate();
    const snackbar = useSnackbarStore((state) => state);
    const isAdmin = useUserStore((state) => state.isAdmin);
    const [rows, setRows] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [filter, setFilter] = useState<string>('');
    const [filteredRows, setFilteredRows] = useState<any[]>([]);

    useEffect(() => {
        if (!filter) {
            setFilteredRows(rows);
        } else if (isAdmin && filter) {
            const lowerCaseFilter = filter.toLowerCase();
            const filteredRows = JSON.parse(JSON.stringify(rows)).filter((row: any) => {
                if (row.bin.includes(lowerCaseFilter)) {
                    return true;
                } else if (row.title.toLowerCase().includes(lowerCaseFilter)) {
                    return true;
                } else if (`${row.user.last_name || ""} ${row.user.first_name}`?.toLowerCase().includes(lowerCaseFilter)) {
                    return true;
                } else if (row.user.iin?.toLowerCase().includes(lowerCaseFilter)) {
                    return true;
                } else {
                    return false;
                }
            });

            setFilteredRows(filteredRows);
        } else if (!isAdmin && filter) {
            const lowerCaseFilter = filter.toLowerCase();
            const filteredRows = JSON.parse(JSON.stringify(rows)).filter((row: any) => {
                if (row.bin.includes(lowerCaseFilter)) {
                    return true;
                } else if (row.title.toLowerCase().includes(lowerCaseFilter)) {
                    return true;
                } else {
                    return false;
                }
            });

            setFilteredRows(filteredRows);
        }
    }, [filter])

    useEffect(() => {
        setLoading(true);
        getUserSchools()
            .then((res) => {
                setRows(res.data);
            })
            .catch((e) => {
                snackbar.errorMessage(e.response.data.message);
                if (e.response.data.statusCode === 401) {
                    navigate('/auth');
                }
            })
            .finally(() => {
                setLoading(false);
            })
        // eslint-disable-next-line
    }, []);

    const handleCellClick: GridEventListener<'cellClick'> = (params) => {

        if (params.field === 'title') {
            navigate( '/schools/' + params.row.id);
        }

        if (isAdmin && params.field === 'user') {
            navigate( '/user/' + params.row.user.id);
        }

    }

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            headerClassName: 'datagrid--header',
            width: 90,
            sortable: true,
        },
        {
            field: 'bin',
            headerName: 'БИН учебной организации',
            headerClassName: 'datagrid--header',
            width: 200,
            sortable: false,
        },
        {
            field: 'title',
            headerName: 'Название учебной организации',
            headerClassName: 'datagrid--header',
            width: 500,
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return <React.Fragment>
                    <Link
                      to={`/schools/${params.row.id}`}
                      style={{display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black'}}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                        <Avatar
                          sx={{color: '#99999F', bgcolor: '#D9E8FF', mr: 2}}
                        >
                            <DirectionsCar fontSize="small"/>
                        </Avatar>
                        {params.value}
                    </Link>
                </React.Fragment>;
            }
        },
        {
            field: 'phone1',
            headerName: 'Телефон учебной организации',
            headerClassName: 'datagrid--header',
            width: 200,
            sortable: false,
        },
    ];

    const superAdminColumns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            headerClassName: 'datagrid--header',
            width: 90,
            sortable: true,
        },
        {
            field: 'bin',
            headerName: 'БИН учебной организации',
            headerClassName: 'datagrid--header',
            width: 200,
            sortable: false,
        },
        {
            field: 'title',
            headerName: 'Название учебной организации',
            headerClassName: 'datagrid--header',
            minWidth: 400,
            width: 500,
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                  <Link
                      to={`/schools/${params.row.id}`}
                      style={{display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black'}}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                        <Avatar
                          sx={{color: '#99999F', bgcolor: '#D9E8FF', mr: 2}}
                        >
                            <DirectionsCar fontSize="small"/>
                        </Avatar>
                        {params.value}
                    </Link>
                    )
            }
        },
        {
            field: 'user',
            headerName: 'Прикрепленный специалист',
            headerClassName: 'datagrid--header',
            minWidth: 400,
            renderCell: ({row}) => {
                const name = getName(row.user);
                return <Link to={'/user/' + row.user.id} target={'_blank'} style={{textDecoration: 'none', color: 'black', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <Avatar
                        sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                    >
                        {name.partialName}
                    </Avatar>
                    {name.fullName} #{row.user.id}
                </Link>;
            }
        }
    ];

    return (
        <Box>
            <Stack
                flexDirection="row"
                flexWrap="wrap"
                sx={{mb: 2}}
            >
                {!withoutSearch && (
                    <Stack flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                        <Typography sx={{mt: 3}} variant={'h5'}><b>{isAdmin ? 'Зарегистрированные учебные организации' : 'Мои учебные организации'}</b></Typography>
                    </Stack>
                )}
            </Stack>
            {!withoutSearch && <InputBase
                type={'text'}
                onChange={(e) => {
                    setFilter(e.target.value);
                }}
                placeholder={'Поиск'}
                sx={{mb: 1}}
            />}
            <DataGrid
                columns={isAdmin ? superAdminColumns : columns}
                rows={filter ? filteredRows : rows}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                localeText={{
                    MuiTablePagination: {
                        labelDisplayedRows: ({ from, to, count }) =>
                            `${from} - ${to} из ${count}`,
                    },
                }}
                autoHeight={true}
                pageSizeOptions={[10]}
                disableRowSelectionOnClick
                loading={loading}
                sx={{
                    '& .datagrid--header': {
                        border: '1px solid white !important',
                    }
                }}
                disableColumnMenu={true}
                disableColumnFilter={true}
            />
        </Box>
    );
};

export default SchoolsListComponent;
