import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import PageHeader from "../../global/components/PageHeader";
import {
    Avatar,
    Box,
    Button, Checkbox, CircularProgress,
    FormControl,
    FormControlLabel,
    InputBase,
    InputLabel,
    MenuItem,
    Paper,
    Select, SelectChangeEvent, Stack,
    TextField, Typography
} from "@mui/material";
import {useUserStore} from "../../global/store/UserStore";
import {useSnackbarStore} from "../../global/store/SnackbarStore";
import {useNavigate} from "react-router-dom";
import UsersList from "../../Users/components/UsersList";
import {AttachNewSchoolDto} from "../actions/category.type";
import {getLocalitiesByRegionId, getRegions} from "../../Auth/actions/api";
import {blue} from "@mui/material/colors";
import {getName} from "../../global/helpers/text";
import {attachNewSchoolToUser} from "../actions/api";
import {refreshAccessToken} from "../../global/actions/main.api";
import {wrapperSx} from "../../global/helpers/globalstyles";

const inputStyle = { WebkitBoxShadow: "0 0 0 1000px white inset" };

const NewSchoolAdmin = () => {

    const navigate = useNavigate();
    const isAdmin = useUserStore((state) => state.isAdmin);
    const userState = useUserStore((state) => state);
    const snackbar = useSnackbarStore((state) => state);
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [newSchoolInfo, setNewSchoolInfo] = useState<AttachNewSchoolDto>({
        user_id: 0,
        bin: '',
        title: '',
        director: '',
        address: '',
        phone1: '',
        phone2: '',
        region: '',
        locality: '',
        categories: []
    });
    const [regionsArray, setRegionsArray] = useState<any[]>([]);
    const [localitiesArray, setLocalitiesArray] = useState<any[]>([]);
    const [registerLoading, setRegisterLoading] = useState<boolean>(false);
    const [uploadedImages, setUploadedImages] = useState<File[]>([]);
    const imagesRef = useRef<HTMLInputElement>(null);
    const [selectedUser, setSelectedUser] = useState<any>({});
    const [userInfo, setUserInfo] = useState<any>({});

    useEffect(() => {
        if (!isAdmin) {
            setCurrentStep(1);
            setNewSchoolInfo((prev) => {
                return {
                    ...prev,
                    user_id: Number(userState.id)
                }
            })
            chooseUser(userState);
        }
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        getRegions()
            .then((res) => {
                setRegionsArray(res.data);
            })
    }, [])

    const chooseUser = (user: any) => {

        console.log(user);
        setSelectedUser(user);

        const name = getName(user);

        console.log(name);

        setUserInfo({
            partialName: name.partialName,
            fullName: name.fullName
        })

        setNewSchoolInfo((prev) => {
            return {
                ...prev,
                user_id: user.id
            }
        });

        setCurrentStep(1)
    }

    const handleSchoolChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = e.target.value;
        const name = e.target.name;

        setNewSchoolInfo((prev) => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const handleRegionChange = (e: SelectChangeEvent<any>) => {
        setRegion(e.target.value)
    }

    const handleLocalitiesChange = (e: SelectChangeEvent<string>) => {
        setLocality(e.target.value)
    }

    const setRegion = (chosenRegion: any) => {
        setNewSchoolInfo((prev) => {
            return {
                ...prev,
                region: chosenRegion
            }
        })

        let regionId: number;

        regionsArray.map((region) => {
            if (region.name_ru === chosenRegion) {
                regionId = region.id
                getLocalitiesByRegionId(regionId)
                    .then((res) => {
                        if (regionId === 1 || regionId === 2 || regionId === 3) {
                            setLocalitiesArray(res.data);
                        } else {
                            setLocalitiesArray([
                                ...res.data,
                                {
                                    id: 50000,
                                    name_ru: 'Другое (вписать в адрес)'
                                }
                            ])
                        }
                    })
            }
        })
    }

    const setLocality = (chosenLocality: any) => {

        if (chosenLocality === 'Другое (вписать в адрес)') {
            setNewSchoolInfo((prev) => {
                return {
                    ...prev,
                    locality: 'Другое (вписать в адрес)'
                }
            })
            return;
        }

        setNewSchoolInfo((prev) => {
            return {
                ...prev,
                locality: chosenLocality
            }
        })
    }

    const handleCategoriesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        const name = e.target.name;

        if (checked) {
            const newCategories = newSchoolInfo.categories.concat(name);

            setNewSchoolInfo((prev) => {
                return {
                    ...prev,
                    categories: newCategories
                }
            })
        }

        if (!checked) {
            const isIncluded = newSchoolInfo.categories.includes(name);

            if (isIncluded) {
                const newCategories : string[] = [];

                for (let i = 0; i < newSchoolInfo.categories.length; i++) {
                    const element = newSchoolInfo.categories[i];

                    if (element !== name) {
                        newCategories.push(element);
                    }
                }

                setNewSchoolInfo((prev) => {
                    return {
                        ...prev,
                        categories: newCategories
                    }
                })
            }
        }
    }

    // const handleImageEvent = (e: ChangeEvent<HTMLInputElement>) => {
    //     const chosenFiles = Array.prototype.slice.call(e.target.files);
    //     handleAttachedImages(chosenFiles);
    // }

    const handleAttachedImages = (files: File[]) => {
        const uploaded = [...uploadedImages];

        if ((files.length + uploaded.length) > 5) {
            snackbar.errorMessage('Максимально допустимое количество файлов - 5');
            return;
        }
        files.forEach((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                uploaded.push(file);
            }
        })
        setUploadedImages(uploaded);
    }

    const checkSchoolForm = () => {

        if (newSchoolInfo.region === 'Алматы' || newSchoolInfo.region === 'Астана' || newSchoolInfo.region === 'Шымкент') {

        } else {
            if (!newSchoolInfo.locality) {
                return false;
            }
        }

        return !(!newSchoolInfo.bin || !newSchoolInfo.phone1 || !newSchoolInfo.title || !newSchoolInfo.director || newSchoolInfo.categories.length < 1 || !newSchoolInfo.region || !newSchoolInfo.address);
    }

    const finishAdd = () => {

        let schoolInfoDto = {
            ...newSchoolInfo
        };

        if (newSchoolInfo.locality === 'Другое (вписать в адрес)') {
            schoolInfoDto = {
                ...newSchoolInfo,
                locality: ''
            }
        }

        if (checkSchoolForm()) {
            setRegisterLoading(true);
            attachNewSchoolToUser(schoolInfoDto)
                .then((res) => {
                    console.log(res);
                    snackbar.successMessage('Успешно добавлена школа');
                    clearForm();
                    refreshAccessToken()
                        .then(() => {
                            setRegisterLoading(false);
                        })
                })
                .catch((err) => {
                    snackbar.errorMessage(err.response.data.message);
                    setRegisterLoading(false);
                })
        } else {
            snackbar.errorMessage('Необходимо заполнить все поля')
        }
    }

    const clearForm = () => {
        setNewSchoolInfo({
            user_id: 0,
            bin: '',
            title: '',
            director: '',
            address: '',
            phone1: '',
            phone2: '',
            region: '',
            locality: '',
            categories: []
        });

        if (!isAdmin) {
            navigate('/')
        } else {
            setCurrentStep(0);
        }
    }

    const pagesObj = [
        {
            path: '/',
            title: 'Главная'
        },
        {
            path: '/new-school',
            title: 'Прикрепить новую организацию к пользователю'
        }
    ]

    return (
        <Box className="App" sx={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <PageHeader />
            <Box sx={{...wrapperSx}}>
                {currentStep === 0 && (
                    <>
                        <Stack flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'}>
                            <Typography sx={{mt: 3}} variant={'h5'}><b>Добавление новой учебной организации к существующему специалисту</b></Typography>
                        </Stack>

                        <Box>
                            <Typography variant="h6">
                                Специалисты (пользователи)
                            </Typography>
                            <Typography variant="subtitle2">
                                Здесь отображаются все специалисты <b>всех учебных организаций</b>
                            </Typography>
                        </Box>

                        <UsersList
                            setUser={chooseUser}
                        />
                    </>
                )}

                {currentStep === 1 && (
                    <Box className={'register-form'} sx={{p: 5}}>
                        <Box className={'register-form-box'}>

                            <Typography variant={'h6'}>Выбранный пользователь:</Typography>

                            <Stack flexDirection={'row'} alignItems={'center'}>
                                <Avatar
                                    sx={{mr: 1.5, fontSize: 16, color: '#99999F', bgcolor: '#D9E8FF'}}
                                >
                                    {userInfo.partialName ? userInfo.partialName : ''}
                                </Avatar>
                                <Typography>{userInfo.fullName ? userInfo.fullName : ''} #{selectedUser.id}</Typography>
                            </Stack>

                            <Typography variant={'h5'}>Данные учебной организации</Typography>
                             <InputBase
                                    type={'text'}
                                    placeholder={'БИН*'}
                                    value={newSchoolInfo.bin}
                                    name={'bin'}
                                    onChange={handleSchoolChange}
                                    inputProps={{style: inputStyle}}
                                    required={true}
                                    disabled={registerLoading}
                            />
                                <InputBase
                                    type={'text'}
                                    placeholder={'Полное наименование организации*'}
                                    value={newSchoolInfo.title}
                                    name={'title'}
                                    onChange={handleSchoolChange}
                                    inputProps={{style: inputStyle}}
                                    required={true}
                                    disabled={registerLoading}
                                />

                                <InputBase
                                    type={'text'}
                                    placeholder={'Ф.И.О. Директора*'}
                                    value={newSchoolInfo.director}
                                    name={'director'}
                                    onChange={handleSchoolChange}
                                    inputProps={{style: inputStyle}}
                                    required={true}
                                    disabled={registerLoading}
                                />

                                <InputBase
                                    type={'text'}
                                    placeholder={'Основной номер телефона*'}
                                    value={newSchoolInfo.phone1}
                                    name={'phone1'}
                                    onChange={handleSchoolChange}
                                    inputProps={{style: inputStyle}}
                                    required={true}
                                    disabled={registerLoading}
                                />

                                <InputBase
                                    type={'text'}
                                    placeholder={'Дополнительный номер телефона'}
                                    value={newSchoolInfo.phone2}
                                    name={'phone2'}
                                    onChange={handleSchoolChange}
                                    inputProps={{style: inputStyle}}
                                    required={false}
                                    disabled={registerLoading}
                                />

                            <FormControl sx={{mt: 1}}>
                                <InputLabel id={'region-label'}>Регион</InputLabel>
                                <Select
                                    labelId={'region-label'}
                                    onChange={handleRegionChange}
                                    name={'region'}
                                    label={'Регион'}
                                    sx={{
                                        ".MuiOutlinedInput-notchedOutline": {
                                            borderWidth: 0,
                                            boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);"
                                        }
                                    }}
                                    value={newSchoolInfo.region}
                                    required={true}
                                >
                                    {regionsArray.map((region: any) => {
                                        return (
                                            <MenuItem
                                                value={region.name_ru}
                                                key={region.name_ru}
                                            >
                                                {region.name_ru}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>

                            <FormControl sx={{mt: 1}}>
                                <InputLabel id={'locality-label'}>Населенный пункт</InputLabel>
                                <Select
                                    labelId={'locality-label'}
                                    onChange={handleLocalitiesChange}
                                    name={'locality'}
                                    label={'Населенный пункт'}
                                    sx={{
                                        ".MuiOutlinedInput-notchedOutline": {
                                            borderWidth: 0,
                                            boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);"
                                        }
                                    }}
                                    value={newSchoolInfo.locality ? newSchoolInfo.locality : ''}
                                    required={true}
                                    disabled={localitiesArray.length === 0}
                                >
                                    {localitiesArray.map((locality: any) => {
                                        return (
                                            <MenuItem
                                                value={locality.name_ru}
                                                key={locality.name_ru}
                                            >
                                                {locality.name_ru}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>

                            <InputBase
                                type={'text'}
                                placeholder={'Адрес учебной организации'}
                                value={newSchoolInfo.address}
                                name={'address'}
                                onChange={handleSchoolChange}
                                inputProps={{style: inputStyle}}
                                required={false}
                                disabled={registerLoading}
                            />

                            <Box sx={{display: 'flex', flexDirection: 'row', marginTop: '10px', maxWidth: '400px'}}>
                                <Typography variant={'h6'}>Категории учебной организации, по которым разрешено проводить обучение:</Typography>
                            </Box>
                            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                <FormControlLabel className={'category-label'} control={<Checkbox checked={newSchoolInfo.categories.includes('A')} disabled={registerLoading} name={'A'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'А'} />
                                <FormControlLabel className={'category-label'} control={<Checkbox checked={newSchoolInfo.categories.includes('B')} disabled={registerLoading} name={'B'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'B'} />
                                <FormControlLabel className={'category-label'} control={<Checkbox checked={newSchoolInfo.categories.includes('BE')} disabled={registerLoading} name={'BE'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'BE'} />
                                <FormControlLabel className={'category-label'} control={<Checkbox checked={newSchoolInfo.categories.includes('C')} disabled={registerLoading} name={'C'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'C'} />
                                <FormControlLabel className={'category-label'} control={<Checkbox checked={newSchoolInfo.categories.includes('CE')} disabled={registerLoading} name={'CE'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'CE'} />
                            </Box>
                            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                <FormControlLabel className={'category-label'} control={<Checkbox checked={newSchoolInfo.categories.includes('D')} disabled={registerLoading} name={'D'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'D'} />
                                <FormControlLabel className={'category-label'} control={<Checkbox checked={newSchoolInfo.categories.includes('DE')} disabled={registerLoading} name={'DE'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'DE'} />
                                <FormControlLabel className={'category-label'} control={<Checkbox checked={newSchoolInfo.categories.includes('Tb')} disabled={registerLoading} name={'Tb'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'Tb'} />
                                <FormControlLabel className={'category-label'} control={<Checkbox checked={newSchoolInfo.categories.includes('Tm')} disabled={registerLoading} name={'Tm'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'Tm'} />
                            </Box>

                            <Box sx={{display: 'flex', flexDirection: 'row', marginTop: '10px', maxWidth: '400px'}}>
                                <Typography variant={'h6'}>Подкатегории учебной организации, по которым разрешено проводить обучение:</Typography>
                            </Box>
                            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                <FormControlLabel className={'category-label'} control={<Checkbox checked={newSchoolInfo.categories.includes('A1')} disabled={registerLoading} name={'A1'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'A1'} />
                                <FormControlLabel className={'category-label'} control={<Checkbox checked={newSchoolInfo.categories.includes('B1')} disabled={registerLoading} name={'B1'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'B1'} />
                                <FormControlLabel className={'category-label'} control={<Checkbox checked={newSchoolInfo.categories.includes('C1')} disabled={registerLoading} name={'C1'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'C1'} />
                                <FormControlLabel className={'category-label'} control={<Checkbox checked={newSchoolInfo.categories.includes('C1E')} disabled={registerLoading} name={'C1E'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'C1E'} />
                                <FormControlLabel className={'category-label'} control={<Checkbox checked={newSchoolInfo.categories.includes('D1')} disabled={registerLoading} name={'D1'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'D1'} />
                            </Box>

                            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                <FormControlLabel className={'category-label'} control={<Checkbox checked={newSchoolInfo.categories.includes('D1E')} disabled={registerLoading} name={'D1E'} onChange={handleCategoriesChange} className={'register-checkbox'}/>} label={'D1E'} />
                            </Box>

                            {!registerLoading && (<Stack gap={2}>

                                <Button
                                    onClick={() => {
                                        finishAdd()
                                    }}
                                    variant={'contained'}
                                    disabled={registerLoading}
                                >
                                    Добавить
                                </Button>

                                {isAdmin && (<Button
                                    onClick={() => {
                                        setCurrentStep(0)
                                    }}
                                    variant={'contained'}
                                    color={'error'}
                                    disabled={registerLoading}
                                >
                                    Назад
                                </Button>)}

                            </Stack>)}

                            {registerLoading && <CircularProgress />}
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default NewSchoolAdmin;