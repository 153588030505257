import React, {useState} from 'react';
import {
    Alert,
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Paper, Stack,
    SxProps,
    Theme, Tooltip,
    Typography
} from "@mui/material";
import {useUserStore} from "../../../global/store/UserStore";
import GroupStepper from "./GroupStepper";
import {GroupDto} from "../../actions/group.type";
import {CancelOutlined, DeleteOutline} from "@mui/icons-material";
import {deleteGroupApi, getEnrollment} from '../../actions/api';
import { useSnackbarStore } from '../../../global/store/SnackbarStore';
import { useNavigate } from 'react-router-dom';
import {FRONTEND_URL} from "../../../global/actions/apiVariables";
import InfoIcon from '@mui/icons-material/Info';
import AdminChangeDates from './AdminChangeDates';
import AdminChangeTitle from './AdminChangeTitle';
import AdminChangeCategory from "./AdminChangeCategory";

const paperSx: SxProps<Theme> = {
    boxShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 6px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
    padding: 3,
    borderRadius: 4
};

interface GroupMainPageProps {
    groupInfo: GroupDto;
    addStudent: () => void;
    formGroup: () => void;
    fetchGroupInfo: () => void;
}

const GroupMainPage = ({groupInfo, addStudent, formGroup, fetchGroupInfo}: GroupMainPageProps) => {

    const navigate = useNavigate();
    const snackbar = useSnackbarStore((state) => state);
    const currentUser = useUserStore((state) => state);
    const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
    const [deleting, setDeleting] = useState<boolean>(false);

    const handleDelete = () => {
        setDeleting(true);
        deleteGroupApi(groupInfo.id)
            .then((res) => {
                snackbar.successMessage(res.data.message);
                navigate('/groups');
            })
            .catch((err) => {
                snackbar.errorMessage(err.response.data.message);
            })
            .finally(() => {
                setDeleting(false);
            })
    }

    return (
        <Grid container spacing={5}>
            <Grid item xs={12} lg={8} xl={8}>
                <Paper sx={{...paperSx}}>
                    <Typography variant="h4">Статус группы</Typography>
                    <Typography variant="subtitle1" sx={{mb: 1.5}} color="gray">Следуйте данным шагам</Typography>
                    <GroupStepper
                        groupInfo={groupInfo}
                        addStudent={addStudent}
                        formGroup={formGroup}
                        fetchGroupInfo={fetchGroupInfo}
                    />
                </Paper>
            </Grid>
            <Grid item xs={12} lg={4} xl={4}>
                <Paper sx={{...paperSx, display: 'flex', flexDirection: 'column', gap: '10px'}}>
                    <Typography variant="h4" sx={{mb: 1.5}}>Действия</Typography>

                    <Stack flexDirection={'row'} alignItems={'center'}>
                        <Button
                            startIcon={<DeleteOutline/>}
                            color="error"
                            onClick={() => {setConfirmOpen(true)}}
                            disabled={groupInfo.status !== 1 || currentUser.isAdmin}
                            variant={'contained'}
                            sx={{maxWidth: '300px', minWidth: '200px'}}
                        >
                            Удалить группу
                        </Button>

                        <Tooltip title={`Доступно только ДО начала обучения`} sx={{ml: 2}}>
                            <InfoIcon />
                        </Tooltip>
                    </Stack>

                    <Stack flexDirection={'row'} alignItems={'center'}>
                        <Button
                            color="primary"
                            href={`${FRONTEND_URL}/enrollment/${groupInfo.id}`}
                            target={'_blank'}
                            disabled={groupInfo.status < 2}
                            variant={'contained'}
                            sx={{maxWidth: '300px', minWidth: '200px'}}
                        >
                            Приказ о зачислении
                        </Button>

                        <Tooltip title={`Доступно только ПОСЛЕ начала обучения`} sx={{ml: 2}}>
                            <InfoIcon />
                        </Tooltip>
                    </Stack>

                    <Stack flexDirection={'row'} alignItems={'center'}>
                        <Button
                            color="primary"
                            href={`${FRONTEND_URL}/exams-protocol/${groupInfo.id}`}
                            target={'_blank'}
                            disabled={groupInfo.status !== 4}
                            variant={'contained'}
                            sx={{maxWidth: '300px', minWidth: '200px'}}
                        >
                            Протокол экзаменов
                        </Button>

                        <Tooltip title={`Доступно только ПОСЛЕ завершения обучения`} sx={{ml: 2}}>
                            <InfoIcon />
                        </Tooltip>
                    </Stack>

                    <Stack flexDirection={'row'} alignItems={'center'}>
                        <Button
                            color="primary"
                            href={`${FRONTEND_URL}/graduation/${groupInfo.id}`}
                            target={'_blank'}
                            disabled={groupInfo.status !== 4}
                            variant={'contained'}
                            sx={{maxWidth: '300px', minWidth: '200px'}}
                        >
                            Приказ о выпуске группы
                        </Button>

                        <Tooltip title={`Доступно только ПОСЛЕ завершения обучения`} sx={{ml: 2}}>
                            <InfoIcon />
                        </Tooltip>
                    </Stack>
                </Paper>
            </Grid>

            {currentUser.isAdmin && (currentUser.iin === '970812350705' || currentUser.iin === '111122223333') && (<Grid item xs={12} lg={4} xl={4}>
                <Paper sx={{...paperSx, mb: 5, display: 'flex', flexDirection: 'column', gap: 3}}>
                    <Typography variant="h4" sx={{mb: 1.5}}>Действия супер-администратора</Typography>

                    {currentUser.isAdmin && (<Alert severity="info">
                        Осторожно: у вашего аккаунта есть доступ к редактированию всех групп в системе.
                        Поэтому вы можете изменять даже сформированные группы.
                    </Alert>)}

                    <AdminChangeCategory groupInfo={groupInfo} fetchGroupInfo={fetchGroupInfo}/>

                    {groupInfo.status >= 2 && (
                        <AdminChangeDates groupInfo={groupInfo} fetchGroupInfo={fetchGroupInfo}/>)}

                    <AdminChangeTitle groupInfo={groupInfo} fetchGroupInfo={fetchGroupInfo}/>

                    <Button onClick={addStudent} variant={'contained'}>
                        Добавить курсанта
                    </Button>
                </Paper>
            </Grid>)}
            <Dialog open={confirmOpen} onClose={() => {setConfirmOpen(false)}}>
                <DialogTitle sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    {groupInfo.title} <Typography color={'gray'} sx={{ml: 'auto'}}>#{groupInfo.id}</Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography sx={{mb: 1}}><i><b>Внимание! Удаление группы возможно только на стадии набора.</b></i></Typography>
                    <Typography>Подтвердите удаление группы <b>{groupInfo.title}</b></Typography>
                </DialogContent>
                <DialogActions sx={{justifyContent: 'center', pb: 4}}>
                    <Button
                        startIcon={<CancelOutlined/>}
                        color="primary"
                        onClick={() => {setConfirmOpen(false)}}
                        disabled={groupInfo.status !== 1}
                        variant={'outlined'}
                        sx={{mr: 5}}
                    >
                        Закрыть
                    </Button>
                    <Button
                        startIcon={<DeleteOutline/>}
                        color="error"
                        onClick={() => {handleDelete()}}
                        disabled={groupInfo.status !== 1 || deleting}
                        variant={'contained'}
                    >
                        Удалить группу
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default GroupMainPage;